import { AppBar, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const LogoImg = styled.img`
  margin-right: 10px;
`

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
  title: {
    color: 'white',
    letterSpacing: '7px',
    fontWeight: 'bold',
  },
}))

const Topbar = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <LogoContainer>
          <LogoImg
            alt={'J Money Apps Logo'}
            src={'/images/logos/logo.svg'}
            style={{ width: '40px' }}
          />
          <Typography className={classes.title} variant="h5">
            J MONEY APPS
          </Typography>
        </LogoContainer>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
}

export default Topbar
