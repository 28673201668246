import { Avatar, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

const Profile = ({ className, vendorConfig, namespace, ...rest }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 'fit-content',
    },
    avatar: {
      width: 60,
      height: 60,
    },
    name: {
      marginTop: theme.spacing(1),
    },
    button: {
      margin: 10,
      backgroundColor: vendorConfig.primaryColor,
      color: 'white',
      '&:hover, &:focus': {
        color: vendorConfig.primaryColor,
        backgroundColor: 'white',
      },
    },
  }))
  const classes = useStyles()

  const user = {
    name: `${vendorConfig.name.titleCase} for Hootsuite`,
    avatar: `/images/${namespace}/apps/${vendorConfig.name.lowerCase}/${vendorConfig.name.lowerCase}-256.png`,
    bio: 'Hootsuite App',
  }

  const hsAppUrl =
    vendorConfig.name.lowerCase === 'wordpress'
      ? `https://apps.hootsuite.com/apps/wordpress-app`
      : `https://apps.hootsuite.com/apps/${vendorConfig.name.lowerCase}`

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt={`${vendorConfig.name.lowerCase} Logo`}
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar}
        to={`/${namespace}/user-guide/${vendorConfig.name.lowerCase}`}
        variant={'rounded'}
      />
      <Typography className={classes.name} variant={'h4'}>
        {user.name}
      </Typography>
      <Typography variant="body2">{user.bio}</Typography>
      <Button
        className={classes.button}
        variant={'contained'}
        color={'primary'}
        onClick={() => window.open(hsAppUrl, '_blank')}
      >
        Install
      </Button>
    </div>
  )
}

Profile.propTypes = {
  className: PropTypes.string,
}

export default Profile
