import styled from 'styled-components'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { ArrowDropDown, CheckBoxOutlined } from '@material-ui/icons'
import ListItemIcon from '@material-ui/icons/GridOnOutlined'

const Container = styled.section`
  background: white;
`

const AppName = styled.span`
  font-weight: bold;
`

const AddStreamContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
`

const StreamsLocationContainer = styled.div``

const LocationOption = styled.div`
  display: flex;
  align-items: center;
  margin: '10px 0';
`

const AddToStreamsButton = styled.div`
  display: flex;
  align-items: center;
  width: 220px;
  background: #fba919;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 2px;
  font-weight: bold;
`

const StreamBody = ({ app }) => {
  return (
    <Container>
      <p>
        To work with this app, add new <AppName>{app.name.titleCase}</AppName>
      </p>
      <p style={{ margin: '10px 0' }}>Add the following streams:</p>
      <AddStreamContainer>
        <CheckBoxOutlined style={{ marginRight: '5px' }} />
        <AppName>{app.name.titleCase}</AppName>
      </AddStreamContainer>
      <p style={{ margin: '10px 0' }}>
        Where do you want to put your new streams?
      </p>
      <StreamsLocationContainer>
        <LocationOption>
          <RadioButtonCheckedIcon />
          create a new board named {app.name.titleCase}
        </LocationOption>
        <LocationOption>
          <RadioButtonUncheckedIcon />
          add them to my existing board{' '}
          <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
            Default Tab
          </span>
          <ArrowDropDown style={{ marginLeft: '20px' }} />
        </LocationOption>
      </StreamsLocationContainer>
      <AddToStreamsButton>
        Add to <ListItemIcon style={{ margin: '0 10px' }} /> Streams
      </AddToStreamsButton>
    </Container>
  )
}

export default StreamBody
