import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SectionImg = styled.img`
  width: 100%;
`

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    margin: '10px 0',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  media: {
    padding: theme.spacing(6),
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
}))

const GenericSection = ({
  mondayApp,
  className,
  sectionText,
  sectionImageUrl,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <section>
      <Grid item {...rest} className={clsx(classes.root, className)}>
        <Typography
          variant={mondayApp ? undefined : 'h5'}
          style={{ marginBottom: '20px' }}
        >
          {sectionText}
        </Typography>
      </Grid>
      {sectionImageUrl ? (
        <Grid item>
          <div className={classes.media}>
            <SectionImg alt="Screenshot 1" src={sectionImageUrl} />
          </div>
        </Grid>
      ) : null}
    </section>
  )
}

GenericSection.propTypes = {
  className: PropTypes.string,
}

GenericSection.defaultProps = {
  sectionText: '',
  sectionImageUrl: '',
}

export default GenericSection
