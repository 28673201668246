import { Divider, Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { HootsuiteSidebarNav, MondaySidebarNav, Profile } from './components'

import config from 'config'
import { getNamespaceFromLocation, getVendorConfig } from 'utils'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 340, // Matches Main.js width
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = ({ open, variant, onClose, className, ...rest }) => {
  const location = useLocation()
  const namespace = getNamespaceFromLocation({ location })
  const vendorConfig = getVendorConfig({ config, location })
  const classes = useStyles()

  return (
    <Drawer
      anchor={'left'}
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        {vendorConfig && (
          <Profile vendorConfig={vendorConfig} namespace={namespace} />
        )}
        {vendorConfig && <Divider className={classes.divider} />}
        <HootsuiteSidebarNav
          vendorConfig={vendorConfig}
          className={classes.nav}
        />
        <MondaySidebarNav vendorConfig={vendorConfig} className={classes.nav} />
        <Divider className={classes.divider} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
