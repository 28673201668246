const PluginBody = ({ app }) => {
  return (
    <div>
      <p>
        The app <span style={{ fontWeight: 'bold' }}>{app.name.titleCase}</span>{' '}
        added a new content source. You can find it in the Content tab in
        Planner. <span style={{ fontWeight: 'bold' }}>Open the new source</span>
      </p>
    </div>
  )
}

export default PluginBody
