import ToggleOnIcon from '@material-ui/icons/ToggleOn'
import styled from 'styled-components'

const Container = styled.section`
  display: flex;
  align-items: center;
  background: white;
  margin: 10px 0;
`

const AppName = styled.span`
  margin: 0;
  font-weight: bold;
`

const PluginBody = ({ app }) => {
  return (
    <div>
      <p>
        This app allows you to send content from your streams to{' '}
        <AppName>{app.name.titleCase}</AppName>. Turn the following plugins on
        or off.
      </p>
      <Container>
        <ToggleOnIcon style={{ marginRight: '10px' }} fontSize={'large'} />
        <AppName>{app.name.titleCase}</AppName>
      </Container>
    </div>
  )
}

export default PluginBody
