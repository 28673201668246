import HootsuiteKnownLimitations from './HootsuiteKnownLimitations'

const CustomMondayKnownLimitations = ({ app }) => {
  return (
    <div>
      {app?.name?.lowerCase === 'hootsuite' && <HootsuiteKnownLimitations />}
    </div>
  )
}

export default CustomMondayKnownLimitations
