import { Avatar, Divider } from '@material-ui/core'
import styled from 'styled-components'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import { format } from 'date-fns'
import NatureImg1 from '../../../../../../../../../../assets/img/marian-kroell-bfgccTqgmCI-unsplash.jpg'
import NatureImg2 from '../../../../../../../../../../assets/img/mike-palmowski-zVIcZ5sSBzo-unsplash.jpg'
import NatureImg3 from '../../../../../../../../../../assets/img/vadim-sadovski-oCq-efwCWDo-unsplash.jpg'
import PluginEllipsis from './PluginEllipsis'

const ExternalLink = styled.a`
  text-decoration: none;
`

const Post = styled.div`
  margin: 20px;
`

const PostHeader = styled.div`
  display: flex;
  margin: 10px 0;
`

const PostTitle = styled.div`
  margin: 0 10px;
`

const PostUsername = styled.div`
  font-weight: bold;
  color: dodgerblue;
`

const PostTimestamp = styled.div``

const PostSubTitle = styled.div`
  color: dodgerblue;
`

const PostText = styled.p`
  color: black;
`

const PostHashtags = styled.p`
  color: dodgerblue;
`

const PostImage = styled.img`
  margin: 10px 0;
  height: 300px;
  width: 100%;
`

const PostFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: grey;
`

const PostCommentContainer = styled.div`
  display: flex;
  align-items: center;
`

const PostComment = styled.div`
  border: 1px solid lightgrey;
  width: 100%;
  height: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  color: lightgrey;
`

const randImg = () => {
  const images = [NatureImg1, NatureImg2, NatureImg3]
  return images[Math.floor(Math.random() * images.length)]
}

const HsPost = ({ app }) => {
  return (
    <Post>
      <PostHeader>
        <Avatar src={''} />
        <PostTitle>
          <PostUsername>AppDemo Jody</PostUsername>
          <PostTimestamp>{format(Date.now(), 'MMM d h:mm a')}</PostTimestamp>
        </PostTitle>
        <PostSubTitle>@appdemojody</PostSubTitle>
      </PostHeader>
      <PostText>
        Here is some text that is for a social post that is supposed to be less
        than one hundred characters.
      </PostText>
      <PostHashtags>#blessed #hashtag #taghash #gojaysgo</PostHashtags>
      <ExternalLink
        target={'_blank'}
        rel={'noopener nofollow'}
        href={
          'https://unsplash.com/photos/bfgccTqgmCI?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink'
        }
      >
        <PostImage src={randImg()} />
      </ExternalLink>
      <PostFooter>
        <FavoriteIcon />
        <ChatBubbleIcon style={{ margin: '0 10px' }} />

        <PluginEllipsis app={app} />
      </PostFooter>
      <PostCommentContainer>
        <Avatar src={''} />
        <PostComment>Write a comment...</PostComment>
      </PostCommentContainer>
      <Divider />
    </Post>
  )
}

export default HsPost
