import styled from '@emotion/styled'
import config from 'config'
import { useLocation } from 'react-router-dom'

const Container = styled.div`
  margin: 20px;
  border: 2px solid grey;
  border-radius: 5px;
  padding: 10px;
  background: lightgrey;
  color: black;
  font-weight: bold;
`

const AppsBracket = styled.div`
  margin-left: 20px;
`

const AppsMiddle = styled.div`
  margin-left: 40px;
  display: flex;
  align-items: center;
`

const ArrayBracket = styled.div``

const Apps = styled.div``

const AppsValue = styled.div``

const Brace = styled.div`
  margin-left: 0px;
`

const Middle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`

const ClientId = styled.div``

const ClientIdValue = styled.div`
  margin-left: 10px;
`

const MondayDomainOwnership = () => {
  const location = useLocation()
  const parts = location.pathname.split('/')
  const vendorName = parts[3]
  const app = config.monday.apps.find((a) => a.name.lowerCase === vendorName)

  return (
    <Container>
      <AppsBracket>&#123;</AppsBracket>
      <AppsMiddle>
        <Apps>&quot;apps&quot;:</Apps>
        <AppsValue>
          <Middle>
            <ArrayBracket>&#91;</ArrayBracket>
            <Brace>&#123;</Brace>
            <ClientId>&quot;clientId&quot;:</ClientId>
            <ClientIdValue>&quot;{app.mondayClientId}&quot;</ClientIdValue>
            <Brace>&#125;</Brace>
            <ArrayBracket>&#93;</ArrayBracket>
          </Middle>
        </AppsValue>
      </AppsMiddle>
      <AppsBracket>&#123;</AppsBracket>
    </Container>
  )
}

export default MondayDomainOwnership
