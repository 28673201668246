import { ExternalLink } from 'components/ExternalLink'

const { Card, CardContent, Typography } = require('@material-ui/core')

const MondayAIImageCreatorHowToUse = ({ app }) => {
  return (
    <div>
      <Card id={'how-to'} style={{ marginTop: '80px' }}>
        <CardContent>
          <Typography
            style={{ fontWeight: '700' }}
            color="textSecondary"
            gutterBottom
            variant="h1"
          >
            How To Use
          </Typography>
          <Card style={{ margin: '20px 0', padding: '10px' }}>
            <Typography style={{ fontWeight: 'bold' }}>
              Start Point: The AI Image Creator Template
            </Typography>
            <Typography>
              From a board view in monday.com, click the Add Item "+" button
              (next to the Search bar on the left).{' '}
            </Typography>
            <Typography>
              Click the Choose from Template option, and search for AI Image
              Creator Template.
            </Typography>
            <Typography>
              This will add a board containing a Prompt Text column and a Create
              Image button.
            </Typography>
            <Typography>
              Once those columns are set up, click the Integrate button above
              your board. And click the + Add new integration button.
            </Typography>
            <Typography>
              A setup screen will appear showing your app plan. If you are using
              the Owner plan, you will need to add your own OpenAI Key. If you
              are using the Basic or Plus plan, click Continue.
            </Typography>
            <Typography>
              An integration recipe sentence will appear. Select the Text or
              Long Text column and Button column you just added.
            </Typography>
          </Card>
          <Card style={{ margin: '20px 0', padding: '10px' }}>
            <Typography style={{ marginTop: '20px', fontWeight: 'bold' }}>
              Start Point: An existing monday.com board
            </Typography>
            <Typography>
              Use an existing board view that you want to use.
            </Typography>
            <Typography>
              Add a Long Text or Text column for the image creation prompt.
            </Typography>
            <Typography>
              Add a Button column that will be used to initiate the image
              creation.
            </Typography>
            <Typography>
              Once those columns are set up, click the Integrate button above
              your board. And click the + Add new integration button.
            </Typography>
            <Typography>
              A setup screen will appear showing your app plan. If you are using
              the Owner plan, you will need to add your own OpenAI Key. If you
              are using the Basic or Plus plan, click Continue.
            </Typography>
            <Typography>
              An integration recipe sentence will appear. Select the Text or
              Long Text column and Button column you just added.
            </Typography>
          </Card>
          <Typography style={{ margin: '20px 0' }}>
            Now type a prompt in the Prompt Text column and click the Create
            Image button.
          </Typography>
          <Typography style={{ fontWeight: 'bold' }}>
            NOTE: The app has a low rate limit per minute, creating multiple
            images at once is not advised. See more details at{' '}
            <ExternalLink
              href={`https://platform.openai.com/docs/guides/rate-limits/free-tier-rate-limits`}
            >
              OpenAI's rate limit policy.
            </ExternalLink>
          </Typography>
          <Card
            style={{ margin: '20px 0', width: 'fit-content', padding: '10px' }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/6H4ZifhTHnA?si=nLxRI1BBJJrhtB5a&rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Card>
        </CardContent>
      </Card>
      <Card id={'how-to'} style={{ marginTop: '80px' }}>
        <CardContent>
          <Typography
            style={{ fontWeight: '700' }}
            color="textSecondary"
            gutterBottom
            variant="h1"
          >
            Rate Limits
          </Typography>
          <Card style={{ margin: '20px 0', padding: '10px' }}>
            <Typography style={{ fontWeight: 'bold' }}>
              OpenAI's rate limit policy
            </Typography>
            <Typography>
              The AI Image Creator integration has rate limits due to OpenAI's
              rate limit policy. The limit will depend on the AI Image Creator
              subscription plan on monday.com. Note that some plans have shared
              usage limits. This is because those plans are using the app's
              OpenAI Key. Users on the{' '}
              <span style={{ fontWeight: 'bold', color: 'green' }}>Owner</span>{' '}
              plans do not have shared usage limits but still have usage limits.
            </Typography>
            <Card
              style={{
                borderLeft: '5px solid royalblue',
                margin: '20px 0 ',
                padding: '10px',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>Basic</Typography>
              <Typography>Monthly Quota: 100 Images / Month</Typography>
              <Typography>
                Rate Limit: 5 Images / Minute (Usage shared between all other
                users on the Basic and Plus plan)
              </Typography>
            </Card>
            <Card
              style={{
                borderLeft: '5px solid royalblue',
                marginBottom: '20px',
                padding: '10px',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>Plus</Typography>
              <Typography>Monthly Quota: 300 Images / Month</Typography>
              <Typography>
                Rate Limit: 5 Images / Minute (Usage shared between all other
                users on the Basic and Plus plan)
              </Typography>
            </Card>
            <Card
              style={{
                borderLeft: '5px solid green',
                marginBottom: '20px',
                padding: '10px',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>Owner</Typography>
              <Typography>Monthly Quota: Unlimited Images / Month</Typography>
              <Typography>
                Rate Limit: 5 Images / Minute (Usage shared between all other
                users on the Basic and Plus plan)
              </Typography>
            </Card>
          </Card>
        </CardContent>
      </Card>
    </div>
  )
}

export default MondayAIImageCreatorHowToUse
