const monday = {
  iconUrl: '/images/logos/monday/monday-256.png',
  urls: {
    base: 'https://monday.com',
    contentSource:
      'https://hootsuite.com/dashboard#/publisher/contentsource?componentid=',
    installUrl: `https://auth.monday.com/oauth2/authorize?response_type=install&client_id=`,
  },
  url: 'https://monday.com',
  feedbackUrl: 'https://jodychambers.typeform.com/to/uhgXPysa',
  apps: [
    {
      namespace: 'monday',
      primaryColor: '#000000',
      name: {
        lowerCase: 'ai-image-creator',
        titleCase: 'AI Image Creator',
      },
      mondayClientId: `f2e74b7633e9f2ec211c884da1f3d2fe`,
      hasAuth: false,
      needsCookies: false,
      components: ['Integration'],
      registerText: 'No OpenAI account required.',
      url: 'https://openai.com',
      description: `AI Image Creator uses DALL·E to create realistic images and art from a description in natual language. Create AI generated images using DALL·E from a text field that will automatically be attached to a monday.com item.`,
      blurb: 'Create AI generated images directly in monday.com',
      categories: [''],
      tags: [
        'design',
        'creation',
        'publish',
        'media',
        'images',
        'dalle',
        'dall-e',
        'open ai',
        'chat gpt',
      ],
      price: '2.50',
      features: [
        'Create AI generated images directly in monday.com.',
        'Type the text prompt in a Long Text or Text field and click the Create Image button.',
        `View new image in the monday.com item's update`,
      ],
      hasHsAuth: true,
      faqs: [
        {
          q: `I'm trying to create multiple AI images at once, the app is not happy, why?`,
          a: {
            text: `The app has a low rate limit per minute, creating multiple images at once is not advised. See more details at https://platform.openai.com/docs/guides/rate-limits/free-tier-rate-limits`,
          },
        },
        {
          q: `What dimensions are the AI created images?`,
          a: {
            text: `The dimensions are 1024x1024.  If you need different sizes, please use the feedback form: https://jodychambers.typeform.com/to/uhgXPysa`,
          },
        },
        {
          q: `How do I see my OpenAI usage?`,
          a: {
            text: `Login to your OpenAI account and go to usage: https://platform.openai.com/usage`,
          },
        },
      ],
      privacy: {
        dataItemsStored: {},
      },
    },
    {
      namespace: 'monday',
      primaryColor: '#ea4c89',
      name: {
        lowerCase: 'dribbble',
        titleCase: 'Dribbble',
      },
      mondayClientId: `cb6153edccbdb08cc85bbc7b907a0637`,
      hasAuth: false,
      needsCookies: false,
      components: ['Board View'],
      registerText: 'A Dribbble account is required.',
      url: 'https://dribbble.com',
      description: `Dribbble is the leading destination to find & showcase creative work and home to the world's best design professionals. Now monday.com users will be able to embed their Dribbble shots directly monday.com board views.  Embed as many images as needed in different board views, each saving their own size.`,
      blurb: 'View your Dribbble shots directly in monday.com',
      categories: [''],
      tags: ['design', 'creation', 'publish', 'media', 'images', 'shots'],
      price: '2.50',
      features: [
        'View your dribbble shots in any monday.com board view.',
        'Use a different dribbble image url for each monday.com board.',
        'Change and save the height of the embeded image for each monday.com board view.',
      ],
      hasHsAuth: true,
      privacy: {
        dataItemsStored: {
          // Hootsuite: ['ID', 'Tokens', 'Timezone'],
          // Monday: [
          //   'ID',
          //   'Tokens',
          //   'Board ID',
          //   'Group ID',
          //   'Field Mappings (Field IDs)',
          // ],
        },
      },
    },
    {
      namespace: 'monday',
      primaryColor: '#00cc6f',
      name: {
        lowerCase: 'hootsuite',
        titleCase: 'Hootsuite',
      },
      mondayClientId: `8a2947f4e6e5f5a2edeb728b7891fc5f`,
      hasAuth: true,
      needsCookies: false,
      components: ['App Plugin'],
      registerText: 'A Hootsuite account is required.',
      url: 'https://hootsuite.com',
      description: `Streamline your social media campaigns workflows - plan, track and publish all posts from one place. monday.com users will be able to see all of their Hootsuite scheduled posts directly from their Calendar view and change when posts are shared alongside any content or projects available in monday.com.`,
      blurb: 'Create a monday.com task directly from Hootsuite.',
      categories: [''],
      tags: ['spreadsheet', 'sheet', 'excel', 'database', 'table'],
      price: 'FREE',
      features: [
        'Create a new Hootsuite scheduled message from your monday.com board.',
        'All updates from monday.com will be automatically synced on Hootsuite.',
        'Use the Hootsuite template to create a board easily in monday.com.',
      ],
      hasHsAuth: true,
      faqs: [
        {
          q: `Why can't I find the Hootsuite template in monday.com?`,
          a: {
            text: 'Templates are not supported in the EU region.',
          },
        },
        {
          q: 'What file types are supported?',
          a: {
            text: 'Depending on the network, image and Video: png, jpeg, jpg, gif, mp4, mov.  See more information here: https://developer.hootsuite.com/docs/uploading-media#valid-media-types',
          },
        },
        {
          q: 'What social networks are supported?',
          a: {
            text: 'Facebook Page, Facebook, Facebook Group, Instagram Business, Twitter LinkedIn, LinkedIn Pages, LinkedIn Groups and Threads',
          },
        },
        {
          q: `What are the restrictions when uploading video and image?`,
          a: {
            text: 'Each social network has different restrictions, see here for more details: https://developer.hootsuite.com/docs/uploading-media#media-restrictions',
          },
        },
        {
          q: 'Can I login with multiple accounts?',
          a: {
            text: 'Multiple monday.com accounts are supported, but only one Hootsuite account.',
          },
        },
        {
          q: 'Is there an easy way to setup my board with all of the correct fields?',
          a: {
            text: 'Yes, using the Hootsuite Template in the monday.com app directory.  Note: this is only supported outside the EU.',
          },
        },
        {
          q: 'Why do my uploaded images not appear in the Hootsuite scheduled message?',
          a: {
            text: 'To upload images correctly to a Hootsuite scheduled message, first upload an image with the correct filetype (png, jpeg) and under the maximum size allowed (5MB).  Once you have uploaded the file, wait a few seconds as the images is being processed.  The image will be attached when a mediaId shows up in the Media IDs field.  If you have multiple images, multiple mediaIds will need to appear before the images are correctly uploaded.',
          },
        },
        {
          q: 'How do the rows in monday.com sync with Hootsuite?',
          a: {
            text: 'When you first create a row in monday.com containing the social message information, the message is not yet synced with Hootsuite.  You will need to click the Schedule button in order to create / sync the message on Hootsuite.  A message ID will appear in the Message IDs field to verify that the sync is complete.  When you update the row in monday.com, a new message ID will appear.',
          },
        },
        {
          q: `I'm seeing this message in my monday.com item's updates: "Hootsuite to monday.com Fields not mapped. Please navigate to the Hootsuite view tab to map them.", why is this?`,
          a: {
            text: `The Hootsuite for monday.com integration requires Hootsuite to monday.com fields to be mapped.  The fields are not mapped correctly. To correctly map the fields, read through this user guide.  If you no longer use the integration, you can remove the custom actions / triggers and uninstall the Hootsuite for monday.com integration.`,
          },
        },
        {
          q: `Can I used multiple monday.com boards with the integration?`,
          a: {
            text: `Only one monday.com board should be connected to the integration.`,
          },
        },
      ],
      privacy: {
        dataItemsStored: {
          Hootsuite: ['ID', 'Tokens', 'Timezone'],
          Monday: [
            'ID',
            'Tokens',
            'Board ID',
            'Group ID',
            'Field Mappings (Field IDs)',
          ],
        },
      },
    },
  ],
}

export default monday
