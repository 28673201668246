import GeneralPrivacy from './GeneralPrivacy'

const Privacy = ({ namespace, app }) => {
  return (
    <div id={'privacy'}>
      <GeneralPrivacy app={app} namespace={namespace} />
    </div>
  )
}

export default Privacy
