const AttachmentsNotUploading = () => {
  return (
    <iframe
      style={{ borderRadius: '5px' }}
      width="560"
      height="315"
      src="https://www.youtube.com/embed/o-gFrFhIpC0?si=T0G8SWNXQQ2JtD7E&amp;start=543"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  )
}

export default AttachmentsNotUploading
