const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked'
  }
}

const c = {
  checked,
}

export default c
