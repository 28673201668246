import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Accordion,
} from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { useState } from 'react'
import config from '../../../../../../../../../config'

const FaqItemsContainer = styled.div`
  margin: 30px 0;
`

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    margin: '80px 0',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  media: {
    paddingTop: theme.spacing(2),
    height: 400,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto',
    },
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
  accordion: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '90%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionSummary: {
    background: '#d3d3d354',
    borderLeft: '4px solid slategrey',
    borderRadius: 'inherit',
    padding: '10px',
  },
}))

const StoredDataItems = ({ items }) => {
  const dataItemsStored = Object.entries(items).map(([key, value]) => {
    return (
      <div key={key} style={{ margin: '10px 0' }}>
        <Typography style={{ fontWeight: 'bold' }}>{key}:</Typography>
        {value.map((v, i) => (
          <Typography key={i} style={{ marginLeft: '20px' }}>
            - {v}
          </Typography>
        ))}
      </div>
    )
  })
  return (
    <div style={{ margin: '10px 0' }}>
      <Typography>The data requested is listed: </Typography>
      {dataItemsStored}
    </div>
  )
}

const Privacy = ({ namespace, app }) => {
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const classes = useStyles()

  const feedbackUrl = config[namespace].feedbackUrl
  return (
    <section>
      <Card className={clsx(classes.root)}>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="h1"
              >
                Privacy
              </Typography>
              <FaqItemsContainer>
                <div className={classes.accordion}>
                  <Accordion
                    expanded={expanded === `panel-privacy`}
                    onChange={handleChange(`panel-privacy`)}
                  >
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panelbh-content"
                      id={`panel-privacy-bh-header`}
                    >
                      <Typography className={classes.heading}>
                        Privacy Statement
                      </Typography>
                      <Typography className={classes.secondaryHeading}>
                        {new Date().getFullYear()}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <Typography style={{ margin: '20px 0' }}>
                          J Money Apps Inc. built the Hootsuite app linked to
                          this document and any app listed on this site as a
                          Commercial app. This SERVICE is provided by J Money
                          Apps Inc. and is intended for use as is.
                        </Typography>
                        <Typography style={{ margin: '20px 0' }}>
                          This page is used to inform website visitors regarding
                          my policies with the collection, use, and disclosure
                          of Personal Information if anyone decided to use my
                          Service.
                        </Typography>
                        <Typography style={{ margin: '20px 0' }}>
                          If you choose to use my Service, then you agree to the
                          collection and use of information in relation to this
                          policy. The Personal Information that I collect is
                          used for providing and improving the Service. I will
                          not use or share your information with anyone except
                          as described in this Privacy Policy. The terms used in
                          this Privacy Policy have the same meanings as in our
                          Terms and Conditions, which is accessible at the app
                          unless otherwise defined in this Privacy Policy.
                        </Typography>
                        <h4>Information Collection and Use</h4>
                        <Typography style={{ margin: '20px 0' }}>
                          For a better experience, while using our Service, I
                          may require you to provide us with certain personally
                          identifiable information. The information that I
                          request is retained on the app's database and stored
                          securely. The data can be removed upon request at any
                          time.
                        </Typography>
                        <StoredDataItems items={app.privacy.dataItemsStored} />

                        <Typography style={{ margin: '20px 0' }}>
                          The app does use third party services that may collect
                          information used to identify you.
                        </Typography>
                        <h4>Log Data</h4>
                        <Typography style={{ margin: '20px 0' }}>
                          I want to inform you that whenever you use my Service,
                          in a case of an error in the app I collect data and
                          information (through third party products) on your
                          device called Log Data. This Log Data may include
                          information such as your device Internet Protocol
                          (“IP”) address, device name, operating system version,
                          the configuration of the app when utilizing my
                          Service, the time and date of your use of the Service,
                          and other statistics.
                        </Typography>
                        <h4>Cookies</h4>
                        <Typography style={{ margin: '20px 0' }}>
                          Cookies are files with a small amount of data that are
                          commonly used as anonymous unique identifiers. These
                          are sent to your browser from the websites that you
                          visit and are stored on your device's internal memory.
                        </Typography>
                        <Typography style={{ margin: '20px 0' }}>
                          The app may use third party code and libraries that
                          use “cookies” to collect information and improve their
                          services. You have the option to either accept or
                          refuse these cookies and know when a cookie is being
                          sent to your device. If you choose to refuse our
                          cookies, you may not be able to use some portions of
                          this Service.
                        </Typography>
                        <h4>API Tokens</h4>
                        <Typography style={{ margin: '20px 0' }}>
                          Any API Tokens this Service uses is only stored on the
                          app's database. These tokens are used to send a social
                          post from Hootsuite to a Slack channel or user on
                          behalf of the authorized Slack user. These tokens are
                          encrypted before storing. Upon logging out of the
                          Service, the tokens are removed from the database.
                          These tokens can also be revoked from the vendor
                          providing them. The tokens remain on the database
                          until the user clicks Disconnect from the Settings
                          dropdown. If the user wishes to have their data
                          removed by us, they can send a request through this{' '}
                          <a
                            rel={'noopener noreferrer'}
                            target={'_blank'}
                            href={feedbackUrl}
                          >
                            feedback form
                          </a>{' '}
                          or send an email to help@hootsuite.com.
                        </Typography>
                        <h4>Security</h4>
                        <Typography style={{ margin: '20px 0' }}>
                          I value your trust in providing us your Personal
                          Information, thus we are striving to use commercially
                          acceptable means of protecting it. But remember that
                          no method of transmission over the internet, or method
                          of electronic storage is 100% secure and reliable, and
                          I cannot guarantee its absolute security.
                        </Typography>
                        <h4>Links to Other Sites</h4>
                        <Typography style={{ margin: '20px 0' }}>
                          This Service may contain links to other sites. If you
                          click on a third-party link, you will be directed to
                          that site. Note that these external sites are not
                          operated by me. Therefore, I strongly advise you to
                          review the Privacy Policy of these websites. I have no
                          control over and assume no responsibility for the
                          content, privacy policies, or practices of any
                          third-party sites or services.
                        </Typography>
                        <h4>Children’s Privacy</h4>
                        <Typography style={{ margin: '20px 0' }}>
                          These Services do not address anyone under the age of
                          13. I do not knowingly collect personally identifiable
                          information from children under 13. In the case I
                          discover that a child under 13 has provided me with
                          personal information, I immediately delete this from
                          our servers. If you are a parent or guardian and you
                          are aware that your child has provided us with
                          personal information, please contact me so that I will
                          be able to do necessary actions.
                        </Typography>
                        <h4>Changes to This Privacy Policy</h4>
                        <Typography style={{ margin: '20px 0' }}>
                          I may update our Privacy Policy from time to time.
                          Thus, you are advised to review this page periodically
                          for any changes. I will notify you of any changes by
                          posting the new Privacy Policy on this page. These
                          changes are effective immediately after they are
                          posted on this page.
                        </Typography>

                        <div style={{ marginTop: '20px' }}>
                          <a
                            href={feedbackUrl}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                          >
                            Feedback
                          </a>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </FaqItemsContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </section>
  )
}

export default Privacy
