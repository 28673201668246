import {
  Card,
  CardActions,
  CardContent,
  colors,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import ArrowRightAltOutlinedIcon from '@material-ui/icons/ArrowRightAltOutlined'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import config from '../../../../../../../../config'

const useStyles = makeStyles((theme) => ({
  root: { background: colors.grey[100] },
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto 10px auto',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center',
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))

const RequirementCard = ({ className, app, isMonday, ...rest }) => {
  const parts = window.location.pathname.split('/')
  const n = parts[1]
  const namespace = n === 'user-guide' ? 'hootsuite' : n
  const classes = useStyles()
  const registerText = isMonday
    ? `A monday.com account is required.`
    : !app.registerText || app.registerText === ''
    ? `${app.name.titleCase} account required`
    : app.registerText
  const title = isMonday ? 'monday.com' : app.name.titleCase
  const logo = isMonday
    ? `/images/logos/${namespace}/${namespace}-256.png`
    : `/images/${namespace}/apps/${app.name.lowerCase}/${app.name.lowerCase}-256.png`
  const url = isMonday
    ? config.monday.urls.installUrl + app.mondayClientId
    : app.url

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.imageContainer}>
          <img alt={'app'} className={classes.image} src={logo} />
        </div>
        <Typography align={'center'} variant={'body1'}>
          {registerText}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container justifyContent={'space-between'}>
          <Grid className={classes.statsItem} item></Grid>
          <Grid className={classes.statsItem} item>
            <a href={url} target={'_blank'} rel="noopener noreferrer">
              <Typography display={'inline'} variant={'body2'}>
                {isMonday ? `Install on monday.com` : `Go to ${title}`}
              </Typography>
            </a>
            <ArrowRightAltOutlinedIcon className={classes.statsIcon} />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

export default RequirementCard
