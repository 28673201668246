import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import config from '../../../../config'

const Title = styled.h1`
  color: grey;
  margin-bottom: 40px;
`

const Privacy = () => {
  const namespace = `hootsuite`
  const feedbackUrl = config[namespace].feedbackUrl
  return (
    <div>
      <Title>Privacy</Title>
      <Typography>
        J Money Apps built the Hootsuite app linked to this document and any app
        listed on this site as a Commercial app. This SERVICE is provided by J
        Money Apps and is intended for use as is.
      </Typography>
      <Typography>
        This page is used to inform website visitors regarding my policies with
        the collection, use, and disclosure of Personal Information if anyone
        decided to use my Service.
      </Typography>
      <Typography>
        If you choose to use my Service, then you agree to the collection and
        use of information in relation to this policy. The Personal Information
        that I collect is used for providing and improving the Service. I will
        not use or share your information with anyone except as described in
        this Privacy Policy. The terms used in this Privacy Policy have the same
        meanings as in our Terms and Conditions, which is accessible at the app
        unless otherwise defined in this Privacy Policy.
      </Typography>
      <h4>Information Collection and Use</h4>
      <Typography>
        For a better experience, while using our Service, I may require you to
        provide us with certain personally identifiable information. The
        information that I request is retained on your device and is not
        collected by me in any way. The data can be accessed from your browser's
        storage. The data will remain until you have cleared the browser's
        cache. You can remove the data by clearing the browser's cache.
      </Typography>
      <Typography>
        The app does use third party services that may collect information used
        to identify you.
      </Typography>
      <h4>Log Data</h4>
      <Typography>
        I want to inform you that whenever you use my Service, in a case of an
        error in the app I collect data and information (through third party
        products) on your device called Log Data. This Log Data may include
        information such as your device Internet Protocol (“IP”) address, device
        name, operating system version, the configuration of the app when
        utilizing my Service, the time and date of your use of the Service, and
        other statistics.
      </Typography>
      <h4>Cookies</h4>
      <Typography>
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
      </Typography>
      <Typography>
        This Service only uses these “cookies” explicitly for your access token
        to keep you logged into the app. You may clear these out from your
        device if you wish, however you will be prompted to login every time the
        app is accessed. Additionally, the app may use third party code and
        libraries that use “cookies” to collect information and improve their
        services. You have the option to either accept or refuse these cookies
        and know when a cookie is being sent to your device. If you choose to
        refuse our cookies, you may not be able to use some portions of this
        Service.
      </Typography>
      <h4>API Tokens</h4>
      <Typography>
        Any API Tokens this Service uses is only stored on your computer's
        browser. These tokens are encrypted before storing. Upon logging out of
        the Service, the tokens are removed from your browser. These tokens can
        also be revoked from the vendor providing them.
      </Typography>
      <h4>Security</h4>
      <Typography>
        I value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and I cannot guarantee
        its absolute security.
      </Typography>
      <h4>Links to Other Sites</h4>
      <Typography>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by me. Therefore, I strongly advise you
        to review the Privacy Policy of these websites. I have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </Typography>
      <h4>Children’s Privacy</h4>
      <Typography>
        These Services do not address anyone under the age of 13. I do not
        knowingly collect personally identifiable information from children
        under 13. In the case I discover that a child under 13 has provided me
        with personal information, I immediately delete this from our servers.
        If you are a parent or guardian and you are aware that your child has
        provided us with personal information, please contact me so that I will
        be able to do necessary actions.
      </Typography>
      <h4>Changes to This Privacy Policy</h4>
      <Typography>
        I may update our Privacy Policy from time to time. Thus, you are advised
        to review this page periodically for any changes. I will notify you of
        any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately after they are posted on this page.
      </Typography>

      <div style={{ marginTop: '20px' }}>
        <a href={feedbackUrl} target={'_blank'} rel={'noopener noreferrer'}>
          Feedback
        </a>
      </div>
    </div>
  )
}

export default Privacy
