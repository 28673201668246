import { useState } from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Accordion,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import config from '../../../../../../../config'
import styled from 'styled-components'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import GenericSection from '../../../../GenericSection'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const FaqItemsContainer = styled.div`
  margin: 30px 0;
`

const FaqItemStep = styled.div`
  margin: 30px 0;
`

const HelpText = styled.div`
  color: grey;
  display: flex;
  align-items: center;
  margin: 10px 0;
`

const Link = styled.a`
  margin-left: 5px;
`

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    margin: '80px 0',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  media: {
    paddingTop: theme.spacing(2),
    height: 400,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto',
    },
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
  accordion: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '90%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionSummary: {
    background: '#d3d3d354',
    borderLeft: '4px solid slategrey',
    borderRadius: 'inherit',
    padding: '10px',
  },
}))

const Faq = ({ className, app, ...rest }) => {
  const namespace = 'monday'
  const feedbackUrl = config[namespace].feedbackUrl
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <section>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                id={'faq'}
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="h1"
              >
                FAQ
              </Typography>
              {app.faqs && (
                <FaqItemsContainer>
                  <div className={classes.accordion}>
                    {app.faqs.map((faq, i) => (
                      <Accordion
                        key={i}
                        expanded={expanded === `panel${i}`}
                        onChange={handleChange(`panel${i}`)}
                      >
                        <AccordionSummary
                          className={classes.accordionSummary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panelbh-content"
                          id={`panel${i}bh-header`}
                        >
                          <Typography className={classes.heading}>
                            {faq.q}
                          </Typography>
                          {/* <Typography className={classes.secondaryHeading}>
                            I am an accordion
                          </Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {faq.a.steps ? (
                              faq.a.steps.map((step, j) => (
                                <FaqItemStep key={j}>
                                  <Typography variant={'h4'}>
                                    Step {j + 1}
                                  </Typography>
                                  <GenericSection
                                    sectionText={step.text}
                                    sectionImageUrl={
                                      step.imageName
                                        ? `/images/${namespace}/apps/${app.name.lowerCase}/${step.imageName}.png`
                                        : null
                                    }
                                  />
                                </FaqItemStep>
                              ))
                            ) : (
                              <FaqItemStep>
                                <GenericSection
                                  sectionText={faq.a.text}
                                  sectionImageUrl={
                                    faq.a.imageName
                                      ? `/images/${namespace}/apps/${app.name.lowerCase}/${faq.a.imageName}.png`
                                      : null
                                  }
                                />
                              </FaqItemStep>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </FaqItemsContainer>
              )}
              <Typography variant={'h5'}>
                Having issues with the app? Reach out for help using the
                Feedback link.
              </Typography>
              <HelpText>
                <Button variant={'outlined'}>
                  <OpenInNewIcon />
                  <Link
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    href={feedbackUrl}
                  >
                    Feedback
                  </Link>
                </Button>
              </HelpText>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </section>
  )
}

export default Faq
