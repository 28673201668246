import GenericSection from '../../../../../GenericSection'

const PluginInstallation = ({ app }) => {
  return (
    <div>
      <GenericSection
        sectionText={`After clicking Install, a popup modal will appear where you can select to add a ${app.name.titleCase} plugin to an existing Hootsuite tab.`}
      />
    </div>
  )
}

export default PluginInstallation
