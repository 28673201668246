const TriggersIssue = () => {
  return (
    <iframe
      style={{ borderRadius: '5px' }}
      width="560"
      height="315"
      src="https://www.youtube.com/embed/o-gFrFhIpC0?si=t3FOAoigmp33kY8M&amp;start=267"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  )
}

export default TriggersIssue
