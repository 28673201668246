import Installation from '../../GeneralBody/Installation'
import Overview from '../../GeneralBody/Overview'
import Requirements from '../../GeneralBody/Requirements'
import Usage from '../../GeneralBody/Usage'
import DeprecationDetails from './DeprecationDetails'
import DeprecationNotice from './DeprecationNotice'
import GmbGuide from './GmbGuide'

const GmbBody = ({ app }) => {
  return (
    <div>
      <DeprecationNotice />
      <Overview app={app} />
      <Requirements app={app} />
      <Installation app={app} />
      <Usage app={app} />
      <GmbGuide app={app} />
      <DeprecationDetails />
    </div>
  )
}

export default GmbBody
