import { ThemeProvider } from '@material-ui/styles'
import { Component } from 'react'
// import { Chart } from 'react-chartjs-2'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { BrowserRouter } from 'react-router-dom'
import validate from 'validate.js'
import './assets/css/index.css'
import validators from './common/validators'
// import { chartjs } from './helpers'
import Routes from './Routes'
import theme from './theme'

// Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
//   draw: chartjs.draw,
// })

validate.validators = {
  ...validate.validators,
  ...validators,
}

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    )
  }
}
