import HootsuiteGmbBody from './hootsuite/CustomBody/GmbBody'
import HootsuiteBrightcoveBody from './hootsuite/CustomBody/BrightcoveBody'
import HootsuiteBody from './hootsuite/GeneralBody'
import MondayBody from './monday/GeneralBody'
import SlackBody from './hootsuite/CustomBody/SlackBody'

const Body = ({ namespace, app }) => {
  return (
    <div>
      {namespace === 'hootsuite' && app.name.lowerCase === 'slack' ? (
        <SlackBody app={app} />
      ) : namespace === 'hootsuite' && app.name.lowerCase === 'brightcove' ? (
        <HootsuiteBrightcoveBody app={app} namespace={namespace} />
      ) : namespace === 'hootsuite' &&
        app.name.lowerCase === 'gmb-business-messages' ? (
        <HootsuiteGmbBody app={app} />
      ) : namespace === 'hootsuite' ? (
        <HootsuiteBody app={app} />
      ) : (
        <MondayBody app={app} />
      )}
    </div>
  )
}

export default Body
