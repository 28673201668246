import styled from 'styled-components'
import Listing from './Listing'

const HsDashContainer = styled.div`
  overflow: hidden;
`
const Topbar = styled.img`
  display: block;
  max-width: 100%;
`
const LowerDashboard = styled.div`
  display: flex;
`

const Sidebar = styled.img`
  width: 22.4%;
  height: 100%;
`

// const App = styled.img`
//   width: 75%;
//   height: 75%;
// `

const AppDir = ({ app }) => {
  return (
    <HsDashContainer>
      <Topbar
        src={'/images/user-guide/hs-app-dir-top.png'}
        alt={'Hootsuite topbar'}
      />
      <LowerDashboard>
        <Sidebar
          src={'/images/user-guide/hs-app-dir-sidebar.png'}
          alt={'Hootsuite sidebar'}
        />
        <Listing app={app} />
        {/* <App
          src={`/images/apps/${app.name.lowerCase}/${app.name.lowerCase}-app-dir.png`}
          alt={`${app.name.titleCase} in App Directory`}
        /> */}
      </LowerDashboard>
    </HsDashContainer>
  )
}

export { AppDir }
