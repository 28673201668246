import styled from 'styled-components'
import config from 'config'

const Link = styled.a`
  text-decoration: none;
`

const Container = styled.div`
  padding: 2px 10px;
  margin-right: 10px;
  border-radius: 3px;
  background: ${(p) => (p.color ? p.color : '#3f51b5')};
  opacity: 75%;
  font-size: 10px;
  color: white;
`

const Category = ({ category, app, namespace }) => {
  const n = namespace || 'hootsuite'
  const vendorConfig = app
    ? config[n].apps.find((a) => a.name.lowerCase === app)
    : null

  const hsAppUrl =
    app?.name?.lowerCase === 'wordpress'
      ? `https://apps.hootsuite.com/apps/wordpress-app`
      : `https://apps.hootsuite.com/apps/${vendorConfig?.name?.lowerCase}`
  const href = vendorConfig ? hsAppUrl : '#'
  return (
    <Link
      target={vendorConfig ? '_blank' : null}
      rel={'noopener noreferrer'}
      href={href}
    >
      <Container color={vendorConfig && vendorConfig.primaryColor}>
        {vendorConfig ? vendorConfig.name.titleCase : app}
        {category}
      </Container>
    </Link>
  )
}

export default Category
