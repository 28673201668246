import { Card, Divider } from '@mui/material'
import { ExternalLink } from 'components/ExternalLink'
import styled from 'styled-components'
const H1 = styled.h1``

const H2 = styled.h2`
  font-size: 22px;
  color: grey;
  margin: 10px 0;
`
const DeprecationDetails = () => {
  return (
    <div id={'deprecation-plan'}>
      <Card sx={{ padding: '20px' }}>
        <H1 id={'guide'}>Deprecation Plan</H1>
        <div style={{ marginBottom: '30px' }}>
          More details from Google can be read{' '}
          <ExternalLink
            color={'black'}
            underline={'true'}
            href={
              'https://support.google.com/business/answer/14919062?visit_id=638543320423712883-2884132063&p=chat_sunset&rd=1#zippy='
            }
          >
            here
          </ExternalLink>
          .
        </div>
        <H2>Immediately</H2>
        <div style={{ marginBottom: '40px' }}>
          The app will continue to work, new conversations can still be opened
          by customers.
        </div>
        <H2>July 15, 2024</H2>
        <div style={{ marginBottom: '40px' }}>
          The app will not support new messages to come in, 30 days left to
          finish existing conversations.
        </div>
        <H2>Aug 15, 2024</H2>
        <div style={{ marginBottom: '40px' }}>
          The app will be fully shutdown.
        </div>
        <Divider />
        <div style={{ margin: '20px 0' }}>
          For more information, contact help@hootsuite.com.
        </div>
      </Card>
    </div>
  )
}

export default DeprecationDetails
