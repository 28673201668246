import styled from 'styled-components'
import SettingsBody from './SettingsBody'
import { CloseOutlined } from '@material-ui/icons'

const Container = styled.div`
  width: 890px;
  height: 400px;
  margin: 40px 20px;
`

const Modal = styled.div`
  border: 2px solid #153058;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 0px 15px #153058;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`

const HeaderLeft = styled.div`
  font-size: 24px;
  font-weight: bold;
`

const HeaderRight = styled.div`
  background: lightgrey;
  border-radius: 50%;
  padding: 12px;
`

const Body = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`

const BodyLeft = styled.div`
  width: 25%;
`

const BodyRight = styled.div`
  width: 80%;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
`

const CloseButton = styled.div`
  font-weight: bold;
  color: #153058;
  background: lightgrey;
  padding: 15px 25px;
`

const AppComponent = styled.div`
  padding: 10px 30px;
  background: ${(p) => (p.isPrimary ? '#fccc75' : 'white')};
  font-weight: ${(p) => (p.isPrimary ? 'bold' : undefined)};
  &:hover {
    cursor: ${(p) => (p.isPrimary ? 'pointer' : 'not-allowed')};
  }
`

const AppSettings = ({ app }) => {
  const primaryComponentType = app.components[0]

  return (
    <Container>
      <Modal>
        <Header>
          <HeaderLeft>App Settings</HeaderLeft>
          <HeaderRight>
            <CloseOutlined />
          </HeaderRight>
        </Header>
        <Body>
          <BodyLeft>
            <AppComponent isPrimary={primaryComponentType === 'App Stream'}>
              Streams
            </AppComponent>
            <AppComponent isPrimary={primaryComponentType === 'App Plugin'}>
              Streams Plugins
            </AppComponent>
            <AppComponent isPrimary={primaryComponentType === 'Content Source'}>
              Content Source
            </AppComponent>
            <AppComponent isPrimary={primaryComponentType === 'Media Library'}>
              Media Library
            </AppComponent>
          </BodyLeft>
          <BodyRight>
            <SettingsBody app={app} />
          </BodyRight>
        </Body>
        <Footer>
          <CloseButton>Close</CloseButton>
        </Footer>
      </Modal>
    </Container>
  )
}

export default AppSettings
