import styled from 'styled-components'
import { Img } from 'react-image'

const Feature = styled.div`
  font-family: Roboto;
  display: grid;
  grid-gap: 10px 10px;
  grid-template-columns: minmax(265px, 1fr) auto;

  background: slategray;
  margin: 80px 0;
  padding: 20px;
  border-radius: 3px;
`

const FeatureTextContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: white;
`

const FeatureImgContainer = styled.div`
  height: 400px;
  border-radius: 3px;
  overflow: hidden;
`

const FeatureImg = styled(Img)`
  height: 100%;
  width: auto;
  object-fit: contain;
`

const Features = ({ app }) => {
  const namespace = 'hootsuite'
  return (
    <div>
      {app &&
        app.features &&
        app.features.map((feature, i) => (
          <Feature key={i}>
            <FeatureTextContainer>
              <p>{feature}</p>
            </FeatureTextContainer>
            <FeatureImgContainer>
              <FeatureImg
                src={`/images/${namespace}/apps/${
                  app.name.lowerCase
                }/hs-screenshot-${i + 1}.jpg`}
                alt={`Feature ${i + 1}`}
              />
            </FeatureImgContainer>
          </Feature>
        ))}
    </div>
  )
}

export default Features
