import GenericSection from '../../../../../GenericSection'

const StreamInstallation = ({ app }) => {
  return (
    <div>
      <GenericSection
        sectionText={`After clicking Install, a new window will open where you can select to create a new tab or add a ${app.name.titleCase} stream to an existing Hootsuite tab.`}
      />
    </div>
  )
}

export default StreamInstallation
