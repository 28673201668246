import { Dropdown } from 'components/Dropdown/Dropdown'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { Img } from 'react-image'
import styled from 'styled-components'
import MailIcon from '@material-ui/icons/Mail'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'

const Item = styled.div`
  display: flex;
  align-items: center;
`

const PluginEllipsis = ({ app }) => {
  const namespace = 'hootsuite'
  const dropdownButton = { icon: <MoreHorizIcon /> }

  const imgUrl = `/images/${namespace}/apps/${app.name.lowerCase}/${app.name.lowerCase}-256.png`
  const middleItems = [
    {
      text: (
        <Item>
          <MailIcon style={{ color: 'grey', marginRight: '10px' }} />
          Send to Email
        </Item>
      ),
      onClick: () => {},
    },
    {
      text: (
        <Item>
          <LocalOfferIcon style={{ color: 'grey', marginRight: '10px' }} />
          Add Tag
        </Item>
      ),
      onClick: () => {},
    },
    {
      text: (
        <Item>
          <Img
            sizes={'10px'}
            variant={'square'}
            style={{ marginRight: '10px', width: '20px', height: '20px' }}
            src={imgUrl}
          />
          Send to {app ? app.name.lowerCase : 'App'}
        </Item>
      ),
      onClick: () => {},
    },
  ]
  return (
    <Dropdown
      dropdownButton={dropdownButton}
      middleItems={middleItems}
      appName={'slack'}
    />
  )
}

export default PluginEllipsis
