import styled from 'styled-components'

const Container = styled.div`
  margin: 60px 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
`

const VideoIFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Video = ({ app }) => {
  return (
    <div>
      {app && app.youtubeEmbedCode && (
        <Container>
          <VideoIFrame
            src={`https://www.youtube.com/embed/${app.youtubeEmbedCode}?modestbranding=1&rel=0`}
            frameborder={'0'}
            allow={
              'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            }
            allowfullscreen
          ></VideoIFrame>
        </Container>
      )}
    </div>
  )
}

export default Video
