/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { Button, colors, List, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import config from '../../../../../../config'
import SidebarIcon from '../SidebarIcon'
import styled from 'styled-components'
import OtherNav from './OtherNav'

const Title = styled.div`
  font-family: Roboto, sans-serif;
  color: grey;
  margin: 10px 0 20px 0;
`

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

const MondaySidebarNav = ({ className, vendorConfig, ...rest }) => {
  const namespace = 'monday'
  const classes = useStyles()

  return (
    <div>
      <List {...rest} className={clsx(classes.root, className)}>
        <Title>monday.com Apps</Title>
        {config.monday.apps.map((app, i) => (
          <ListItem className={classes.item} disableGutters key={i}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to={`/${namespace}/user-guide/${app.name.lowerCase}`}
            >
              <div className={classes.icon}>
                <SidebarIcon
                  imgSrc={`/images/${namespace}/apps/${app.name.lowerCase}/${app.name.lowerCase}-256.png`}
                  vendorName={app.name.lowerCase}
                />
              </div>
              {app.name.titleCase}
            </Button>
          </ListItem>
        ))}
      </List>
      <OtherNav />
    </div>
  )
}

MondaySidebarNav.propTypes = {
  className: PropTypes.string,
}

export { MondaySidebarNav }
