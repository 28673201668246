import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import config from '../../../../config'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))

const Footer = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        <Link component="a" href="/" target="_blank">
          {config.siteTitle + ' ' + new Date().getFullYear()}
        </Link>
      </Typography>
      <Typography variant="caption">
        All rights are reserved unless they are not reserved, then they are
        unreserved. If you wish to reserve them make a reservation.
      </Typography>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
