const hootsuite = {
  iconUrl: '/images/logos/hootsuite/hootsuite-256.png',
  urls: {
    base: 'https://hootsuite.com',
    contentSource:
      'https://hootsuite.com/dashboard#/publisher/contentsource?componentid=',
  },
  url: 'https://hootsuite.com',
  feedbackUrl: 'https://jodychambers.typeform.com/to/F8GWdP',
  plugin: {
    installFlow: [
      {
        id: 1,
        screenshotUrl:
          'https://app-directory.s3.amazonaws.com/portal/images/wrike-screenshot1.png',
        altText: 'Install 1',
        description: 'Here is where you find the Hootsuite app directory',
      },
      {
        id: 2,
        screenshotUrl:
          'https://app-directory.s3.amazonaws.com/portal/images/wrike-screenshot2.png',
        altText: 'Install 2',
        description: 'Install it!',
      },
      {
        id: 3,
        screenshotUrl:
          'https://app-directory.s3.amazonaws.com/portal/images/wrike-screenshot3.png',
        altText: 'Install 3',
        description: 'Then use it!',
      },
    ],
  },
  baseUrl:
    'https://hootsuite.com/dashboard#/publisher/contentsource?componentid=10433793',
  apps: [
    {
      primaryColor: '#7B68EE',
      name: {
        lowerCase: 'clickup',
        titleCase: 'ClickUp',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Plugin'],
      registerText: 'ClickUp account',
      url: 'https://clickup.com',
      description: `Save time with the all-in-one productivity platform that brings teams, tasks, and tools together in one place. The ClickUp for Hootsuite app allows you to send a social media post to ClickUp directly from Hootsuite.`,
      blurb: 'Create a ClickUp item directly from Hootsuite.',
      categories: [``],
      tags: [
        'plugin',
        'clickup',
        'click up',
        'notes',
        'tasks',
        'task management',
      ],
      price: '$6.99',
      features: [
        'Create a ClickUp task directly from a social message',
        'Set your Assignees, Due Date, Tags for each item from Hootsuite',
        'Original message link is automatically added to the new ClickUp task',
      ],
      faqs: [
        {
          q: `I can't click the "Create" button in the app, what's going on?`,
          a: {
            text: 'At least one Workspace / Space / Folder / List is requred as you can only send the social posts to a ClickUp List',
          },
        },
        {
          q: 'Some of my ClickUp Properties are not appearing in the app, why?',
          a: {
            text: 'Only some of the ClickUp task properties are supported. For more properties please contact using the Feedback link above.',
          },
        },
        {
          q: `Videos not showing, what's the deal with that?`,
          a: {
            text: 'Only images are not supported at this time.',
          },
        },
      ],
    },
    {
      primaryColor: '#000000',
      name: {
        lowerCase: 'dalle',
        titleCase: 'Dall-E',
      },
      isVisible: true,
      hasAuth: false,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Stream'],
      registerText: 'OpenAI account',
      url: 'https://openai.com/dall-e-3/',
      description: `DALL·E 3 understands significantly more nuance and detail than the previous systems, allowing you to easily translate your ideas into exceptionally accurate images.  Create new images directly from Hootsuite and add them to your social messages.`,
      blurb: 'Create a new realistic Dall-E image directly from Hootsuite.',
      categories: [``],
      tags: [
        'DallE',
        'stream',
        'dall-e',
        'Dall-E',
        'dalle',
        'ai',
        'images',
        'natual language',
      ],
      price: '$6.99',
      features: [
        'Create new realistic images and art from a description in natural language',
        'Attach the uniquely created images to a Hootsuite social message',
      ],
      faqs: [
        {
          q: `What version of OpenAi's DALL-E is used?`,
          a: {
            text: 'Currently DALL-E 3.',
          },
        },
        {
          q: `I'm limited to 50 images a month, can I increase this?`,
          a: {
            text: 'You can add your own OpenAI API Key to unlock unlimited image creation at the pricing of OpenAI, see https://openai.com/api/pricing/',
          },
        },
        {
          q: 'I have deleted an image, why is my quota not increase?',
          a: {
            text: 'Once an image is created, a credit is used.  Deleting a previous image will not increase your quota used.',
          },
        },
        {
          q: 'The images created are small, can this be changed?',
          a: {
            text: 'The default image size is 256×256.  If you add your OpenAI API Key, the image size will be increased to 1024x1024.',
          },
        },
        {
          q: 'I have multiple OpenAI accounts, can I use multiple accounts?',
          a: {
            text: 'When you add an OpenAI API Key, that key will be used to generate images.  If you want to change this, go to the Settings dropdown in the top right corner of the screen and click Remove API Key.  Now set another API Key.',
          },
        },
        {
          q: 'I no longer have an OpenAI API Key, can I switch back to the quota verion of the app?',
          a: {
            text: 'Yes.  When you add an OpenAI API Key, your quota will not be affected.  When you remove the key, you will be back to your original quota.',
          },
        },
      ],
    },
    {
      primaryColor: '#e16259',
      name: {
        lowerCase: 'notion',
        titleCase: 'Notion',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Plugin'],
      registerText: 'Notion.com account',
      url: 'https://notion.so',
      description: `One workspace for team collaboration, personal milestones, and everything in between. Notion is for notes, tasks, wikis, and databases. The app blends everyday work apps into one. It serves as an all-in-one workspace for note-taking, task management, and project management. The Notion for Hootsuite app allows you to send a social media post to Notion directly from Hootsuite.`,
      blurb: 'Create a Notion item directly from Hootsuite.',
      categories: [``],
      tags: [
        'plugin',
        'notion',
        'notion.so',
        'notes',
        'tasks',
        'task management',
        'databases',
      ],
      price: '$5.99',
      features: [
        'Create a Notion item directly from a social media posted message',
        'Set your selects, dates, people, multi-selects for each item from Hootsuite',
        'View the social message in Notion with the the original link included',
      ],
      faqs: [
        {
          q: `I can't click the "Create" button in the app, what's going on?`,
          a: {
            text: 'At least one database needs to be created in Notion as you can only send the social posts to Notion databases.',
          },
        },
        {
          q: 'Some of my Notion Properties are not appearing in the app, why?',
          a: {
            text: 'Only the following property types are supported currently: Dates, People, Select, Multi-Select.  For more properties please contact using the Feedback link above.',
          },
        },
        {
          q: 'Some of my Notion Databases are not appearing in the app, why?',
          a: {
            text: 'If the Notion databases have been created after installing and logging into the app, logout and log back in to select the new databases.',
          },
        },
      ],
    },
    {
      primaryColor: '#00749C',
      name: {
        lowerCase: 'wordpress',
        titleCase: 'Wordpress.com',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: true,
      needsCookies: false,
      components: ['App Stream', 'Content Source'],
      registerText: 'Wordpress.com account',
      url: 'https://wordpress.com',
      description: `Wordpress.com for Hootsuite allows you to view posts, comments, and media from your Wordpress.com site. You can like, approve, respond, mark as spam, or delete messages directly within Hootsuite. WordPress.com is a division of Automattic that develops an open-source content management system and related products. It enables users to create websites for various purposes, such as e-commerce, blog, portfolio, etc.`,
      blurb:
        'Easily manage your Wordpress.com posts and content from within Hootsuite and share them on Social Media.',
      categories: [``],
      tags: [
        'blog',
        'wordpress',
        'website',
        'portfolio',
        'e-commerce',
        'ecommerce',
      ],
      price: '$5.99',
      features: [
        'View your published WordPress.com posts, media and comments',
        'View, add and moderate comments from the Comments tab',
        'Share your posts and add media to your social posts in Hootsuite',
        'Manage multiple Wordpress.com accounts',
      ],
    },
    {
      primaryColor: '#092DBD',
      name: {
        lowerCase: 'brightcove',
        titleCase: 'Brightcove',
      },
      isVisible: true,
      hasAuth: false,
      hasHsAuth: true,
      needsCookies: false,
      components: ['App Stream', 'Content Source'],
      registerText: 'Brightcove account with an MP4 Ingest Profile.',
      url: 'https://brightcove.com',
      description: `Brightcove offers a robust video platform that simplifies the delivery of video to every destination and device. The Brightcove app for Hootsuite enables you to view Brightcove videos as a source of content within Hootsuite. Users can select videos from Brightcove and schedule them for distribution to various social media platforms from Hootsuite via the Content Source and Stream components.`,
      blurb:
        'Easily find Brightcove videos from within Hootsuite and share them on Social Media.',
      categories: [``],
      tags: [
        'video',
        'audio',
        'media',
        'entertainment',
        'enterprise',
        'corporate communication',
      ],
      price: 'FREE',
      features: [
        'Search for Brightcove videos and playlists directly from Hootsuite.',
        'Seamlessly associate your Brightcove account with Hootsuite.',
        'Use Brightcove within the Hootsuite Stream or Content Source view.',
        'Schedule and distribute Brightcove videos to multiple social media platforms.',
        'Search for videos easily within the Brightcove Marketing Studio interface.',
      ],
      faqs: [
        {
          q: 'Some videos not showing in app',
          a: {
            text: 'When uploading videos to Brightcove, the ingest profile must include MP4 files.',
          },
        },
        {
          q: 'How many videos / playlists will show up',
          a: {
            text: '100 vidoes / 1000 playlists.  If you have more videos or playlists, you will have to use the search bar to have them show up.',
          },
        },
        {
          q: 'Some videos not uploading to Hootsuite Composer',
          a: {
            text: 'This could be caused by the file size limit (100MB).  The upload may timeout as well if the file is very large.',
          },
        },
        {
          q: 'Different Brightcove account showing',
          a: {
            text: 'If you have multiple Brightcove accounts, make sure you are logged into the correct Brightcove account in your browser.',
          },
        },
        {
          q: 'What criteria does the search bar search on',
          a: {
            text: 'Videos / Playlsits: id, name, description, long description, tags, reference id,  custom fields, custom field name',
          },
        },
      ],
    },
    {
      primaryColor: '#ea4c89',
      name: {
        lowerCase: 'dribbble',
        titleCase: 'Dribbble',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Stream'],
      registerText: 'Create a Dribbble account',
      url: 'https://dribbble.com',
      description: `The Dribbble for Hootsuite app allows you to share your designs on social media. View your Dribbble shots within a Hootsuite Stream or Content Source, attach previews and links to social posts, and share interactive content across your social networks. Dribbble is the leading destination to find & showcase creative work and home to the world's best design professionals.`,
      blurb: 'Share Dribbble shots directly in Hootsuite',
      categories: [``],
      tags: ['design', 'creation', 'publish', 'media', 'images', 'shots'],
      price: '$5.99',
      features: [
        'View Dribbble shots from a Hootsuite Stream or Content Source',
        'Attach shots and the original link to the Hootsuite composer to share on your social networks.',
      ],
    },
    {
      primaryColor: '#3fbcfe',
      name: {
        lowerCase: 'figma',
        titleCase: 'Figma',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Stream'],
      registerText: 'Create a Figma account',
      url: 'https://figma.com',
      description:
        'Whether you are organizing a hackathon, event or just want to share your Figma designs, the Figma for Hootsuite app allows you to share your designs on social media. View your Figma designs within a Hootsuite Stream or Content Source, attach previews and links to social posts, and share interactive content across your social networks. Figma connects everyone in the design process so teams can deliver better products, faster.',
      blurb: 'Share Figma designs directly in Hootsuite.',
      categories: [''],
      tags: ['design', 'creation', 'publish', 'media', 'images'],
      price: '$5.99',
      features: [
        'View Figma designs from a Hootsuite Stream or Content Source.',
        'Attach design preview and design link to the Hootsuite composer to share on your social networks.',
      ],
    },
    {
      primaryColor: '#4262ff',
      name: {
        lowerCase: 'miro',
        titleCase: 'Miro',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Stream'],
      registerText: 'Create a Miro account',
      url: 'https://miro.com',
      description: '',
      blurb: 'Share Miro boards directly in Hootsuite',
      categories: [
        'Whether you are organizing a hackathon, a thinkathon, event or just want to share your Miro board, the Miro for Hootsuite app allows you to share your Miro content on social media. Engage your audience on social media with progress and results using the online collaborative whiteboard platform, Miro. View your Miro Boards within a Hootsuite Stream or Content Source, attach previews and links to social posts, and share interactive content across your social networks.',
      ],
      tags: [
        'design',
        'creation',
        'publish',
        'media',
        'images',
        'whiteboard',
        'whiteboarding',
      ],
      price: '$5.99',
      features: [
        'View Miro Boards from a Hootsuite Stream or Content Source.',
        'Attach Board preview and Board link to the Hootsuite composer to share on your social networks.',
      ],
    },
    {
      primaryColor: '#f26f61',
      name: {
        lowerCase: 'issuu',
        titleCase: 'Issuu',
      },
      isVisible: false,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Stream', 'Content Source'],
      registerText: 'Your all-in-one content system with every tool you need',
      url: 'https://issuu.com',
      description: `Issuu for Hootsuite allows you to pull in your Issuu content into Hootsuite and send it out to your social networks.`,
      blurb: 'View your Issuu content directly from Hootsuite.',
      categories: [''],
      tags: ['issuu', 'issue', 'pdf', 'content'],
      price: 'FREE',
      features: [
        'View Issuu content directly in Hootsuite.',
        'Send content to your social networks.',
      ],
    },
    {
      primaryColor: '#4485f4',
      name: {
        lowerCase: 'gmb-business-messages',
        titleCase: 'Google My Business - Business Messages',
      },
      isVisible: false,
      hasAuth: true,
      hasHsAuth: true,
      needsCookies: false,
      components: ['App Stream'],
      registerText: 'Engage with customers on Google for free.',
      url: 'https://www.google.com/business/',
      description: `Now you can direct-message with your customers in Hootsuite through your Google My Business account. Meet your customers where they are.`,
      blurb: 'Message your Google My Business customers directly in Hootsuite.',
      categories: [''],
      tags: ['gmb', 'messaging', 'messages', 'business messages', 'google'],
      price: 'FREE',
      youtubeEmbedCode: 'hSbAJddVhYg',
      features: [
        'View customer messages coming from Google within a Hootsuite stream',
        'Send messsages to them in real time.',
      ],
    },
    {
      primaryColor: '#833AB4',
      name: {
        lowerCase: 'instagram-grid',
        titleCase: 'Instagram Grid',
      },
      isVisible: true,
      hasAuth: false,
      hasHsAuth: true,
      needsCookies: false,
      components: ['App Stream'],
      registerText: 'Create an Instagram account',
      url: 'https://instagram.com',
      description: `The Instagram Grid app lets you remix your photos and share them with your followers on Instagram directly from Hootsuite.`,
      blurb: 'Arrange images in a grid and schedule them for Instagram.',
      categories: [''],
      tags: ['instagram', 'grid', 'schedule', 'images', 'content'],
      price: 'FREE',
      features: [
        'Send images to Instagram once you have connected your Instagram Personal account for direct publishing in Hootsuite.',
        'Upload and arrange images in a grid pattern to show in Instagram',
        'Drag and drop images to preview how they will look on your Instagram profile.',
        'Create a grid of up to nine images',
        'Publish posts with images to instagram right from a Hootsuite stream',
        'Schedule your Instagram Personal posts in advance, or send directly to Instagram Business (10 minutes delay on send)',
      ],
      faqs: [
        {
          q: 'Cannot schedule to Instagram Business?',
          a: {
            text: 'This is supported now!',
          },
        },
        {
          q: 'Two logins? Why?',
          a: {
            text: 'This is only one login now!',
          },
        },
        {
          q: 'Images are not uploading',
          a: {
            text: 'There are restrictions on type and size of images and videos with the current implementation of the app.  See https://developer.hootsuite.com/docs/uploading-media#valid-media-types for more details.  If your media is within the criteria please reach out to https://hootsuite.com/help for further assistance.',
          },
        },
      ],
    },
    {
      primaryColor: '#00cc6f',
      name: {
        lowerCase: 'monday',
        titleCase: 'monday.com',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: true,
      needsCookies: false,
      components: ['App Plugin'],
      registerText: 'Create a monday.com account today!',
      registerIconName: 'monday-256-old.png',
      url: 'https://monday.com',
      description: `monday.com for Hoostuite allows you to create a monday.com Task from a social message within Hootsuite.  Supported monday.com Columns corresponding to your Board are detected and available to add to your monday.com Task. Stay on top of leads generated from social media as well as listen and triage customer requests from your Hootsuite dashboard. monday.com.com is a cloud-based Work OS, where Teams create workflow apps in minutes to run their processes, projects, and everyday work.`,
      blurb: 'Create a monday.com task directly from Hootsuite.',
      categories: [''],
      tags: ['spreadsheet', 'sheet', 'excel', 'database', 'table'],
      price: '$5.99',
      youtubeEmbedCode: '38dHCq2_FEk',
      features: [
        'Create a monday.com item from a social message directly in Hootsuite',
        'Select the Board and Group and add supported column data to your item',
        'Original message link is automatically added to the new monday.com item',
      ],
      faqs: [
        {
          q: 'Some of my monday.com Board Columns are not appearing in the app, why?',
          a: {
            text: 'Only the following field types are supported currently: Text, Users (Multiple Persons), Labels, Date (Only 1 date field per board)',
          },
        },
      ],
    },
    {
      primaryColor: '#2e9fff',
      name: {
        lowerCase: 'disqus',
        titleCase: 'Disqus',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Stream'],
      registerText: 'Express yourself',
      url: 'https://disqus.com',
      description: `Disqus for Hootsuite allows you to easily moderate comments posted on your website's forums. You can like, approve, respond, mark as spam, or delete messages directly within Hootsuite. Disqus is an embeddable widget or blog plug-in that enhances your website content by integrating it with a fully moderated community forum.`,
      blurb: 'View Disqus forum posts directly in Hootsuite.',
      categories: [''],
      tags: [
        'forum',
        'discuss',
        'discussion',
        'threads',
        'comments',
        'messaging',
      ],
      price: '$5.99',
      features: [
        'View and manage your Disqus forum posts as an admin',
        'Engage with posts on your forum or other forums via likes, replies, and sharing to the Hootsuite Composer.',
        'Moderate comments with options to mark as spam or delete inappropriate items.',
        'Search for specific users and forums or browse recently popular forums.',
      ],
    },
    {
      primaryColor: '#fcb400',
      name: {
        lowerCase: 'airtable',
        titleCase: 'Airtable',
      },
      isVisible: true,
      hasAuth: false,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Plugin'],
      registerText: 'Create, your way',
      url: 'https://airtable.com',
      description:
        'Airtable for Hoostuite allows you to create an Airtable record from a social message.  Map the field for the social message from Hootsuite as well as another optional field for an attachment. Airtable is a provider of a collaboration platform and productivity tool designed to organize and manage data.',
      blurb: 'Create Airtable records directly in Hootsuite',
      categories: [''],
      tags: ['spreadsheet', 'sheet', 'excel', 'database', 'table'],
      price: '$5.99',
      features: [
        'Create an Airtable record from a social message directly in Hootsuite',
        'Select the Base, Table to map the Record fields to the social message and optional attachment',
        'The orginal link to the social message is included in the message to see more details',
      ],
    },
    {
      primaryColor: '#00c4cc',
      name: {
        lowerCase: 'canva',
        titleCase: 'Canva',
      },
      isVisible: true,
      hasAuth: false,
      hasHsAuth: false,
      needsCookies: false,
      components: ['Content Source'],
      registerText: 'Design anything. Publish anywhere.',
      url: 'https://canva.com',
      description:
        'Canva for Hootsuite allows you to create engaging visual content directly from your Hootsuite dashboard. View recently created designs as a Hootsuite content source, add previously created designs into Hootsuite from your Canva folders, and easily attach designs to the Hootsuite Composer to share across your social networks.',
      blurb: 'Create Canva designs directly in Hootsuite',
      categories: [''],
      tags: ['design', 'creation', 'publish', 'media', 'images'],
      price: '$5.99',
      features: [
        'Create Canva designs directly from Hootsuite',
        'View recently created designs in a Hootsuite Content Source',
        'Attach recent designs to the Hootsuite composer to share on your social networks',
        'Add designs into Hootsuite from your Canva folders',
      ],
      faqs: [
        {
          q: 'How do I access my Canva assets?',
          a: {
            steps: [
              {
                text: 'Login to canva.com in a separate browser tab or window.',
                imageName: 'faq-1-step-1',
              },
              {
                text: 'Once you have logged in, you can now trigger the Canva for Hootsuite app from within Hootsuite.  Navigate to the Publisher tab on the left side in the Hootsuite dashboard.  Next, click on the Content tab.  You should be able to see the Canva tab on the left side now.',
              },
              {
                text: 'Once the Canva for Hootsuite app is opened, you can click on the Design with Canva button and select any of the options in the dropdown.',
              },
              {
                text: 'The Canva popup should open and you should see the Folders tab on the left side.  Note: If you are not logged in to canva.com from another browser tab this tab will not appear.',
                imageName: 'faq-1-step-4',
              },
              {
                text: 'Your folders should now appear with the designs able to be selected.',
                imageName: 'faq-1-step-5',
              },
            ],
          },
        },
      ],
    },
    {
      primaryColor: '#1DB954',
      name: {
        lowerCase: 'spotify',
        titleCase: 'Spotify',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Stream'],
      registerText: 'Get Spotify for free.',
      url: 'https://spotify.com',
      description:
        'Spotify for Hootsuite allows you to view and share tracks, playlists, albums and artists directly to the Hootsuite Composer.  With Spotify, discover, manage and share over 50 million tracks, including podcast 700,000 titles, for free, or upgrade to Spotify Premium to access exclusive features for music including improved sound quality and an on-demand, offline, and ad-free listening experience.',
      blurb:
        'View all your Spotify tracks, playslists, albums directly from Hootsuite',
      categories: ['Audio & Video Sharing'],
      tags: ['music', 'audio', 'listen', 'dance', 'pop', 'rock'],
      price: '$5.99',
      features: [
        'View all your playlists, podcasts, audiobooks and explore each artist, album and track or episode',
        'Search for tracks, albums, artists, podcasts, audiobooks and playlists',
        'View extra track data including tempo, danceability as well as available markets',
        'Preview tracks or podcast episodes and share them to the Hootsuite Composer along with any images from the Track, Album, Playlist or Artist',
        'Multiple accounts supported.',
      ],
    },
    {
      primaryColor: '#fc4b00',
      name: {
        lowerCase: 'strava',
        titleCase: 'Strava',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Stream'],
      registerText: "Join Strava today, it's Free.",
      url: 'https://strava.com',
      description:
        'Strava for Hootsuite allows you to view all of your Strava activities, available public races, your clubs.  Use the Send to Composer feature to share these details out to your other social networks. Strava is a mobile application that helps cyclists and athletes to track and share their ride information and fitness activities.',
      blurb: 'View Strava activities from Hootsuite',
      categories: ['Collaboration', 'Customer Service'],
      tags: ['brand monitoring'],
      price: '$5.99',
      features: [
        'View all your Strava activities and club details in the Hootsuite Dashboard',
        'Publish your Strava activities to Facebook, Twitter, and other social networks via the Hootsuite Composer',
        'Promote your Strava club or team activities and manage the growth of your social following in Hootsuite Streams',
      ],
    },
    {
      primaryColor: '#00A651',
      name: {
        lowerCase: 'wrike',
        titleCase: 'Wrike',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Plugin'],
      registerText:
        'Sign in with your existing Wrike team or create one for free now!',
      url: 'https://wrike.com',
      description:
        'Wrike for Hootsuite allows you to select a social media post, set the importance, add assignees, a comment and send it directly to a Wrke task. Wrike enables organizations to achieve Operational Excellence, the state in which work processes are scaled to meet the near real-time service expectations of the on-demand market.',
      blurb: 'Create a Wrike task directly from a Hootsuite social message',
      categories: ['Collaboration', 'Customer Service'],
      tags: ['plugin', 'Wrike', 'brand monitoring'],
      price: '$5.99',
      features: [
        'Create a Wrike task directly from a social media posted message',
        'Set importance, add your assignees of each Wrike project and a comment',
        'Original message link is automatically added to the new Wrike task ',
      ],
    },
    {
      primaryColor: '#5ecc62',
      name: {
        lowerCase: 'basecamp',
        titleCase: 'Basecamp',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Plugin'],
      description:
        'Basecamp for Hootsuite allows you to select a social media post, set the importance, add assignees, a comment and send it directly to a Basecamp task. Basecamp enables organizations to achieve Operational Excellence, the state in which work processes are scaled to meet the near real-time service expectations of the on-demand market.',
      blurb: 'Create a Basecamp task directly from a Hootsuite social message',
      categories: ['Collaboration', 'Customer Service'],
      tags: ['plugin', 'Basecamp', 'brand monitoring'],
      price: '$5.99',
      features: [
        'Create a Basecamp project todo directly from a social media posted message',
        'Select an account, project, to do list, add your assignees and subscribers and a comment',
        'Original message link is automatically added to the new Basecamp todo',
      ],
    },
    {
      primaryColor: '#FB627C',
      name: {
        lowerCase: 'asana',
        titleCase: 'Asana',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Plugin'],
      description:
        'Asana for Hootsuite allows you to select a social media post, add tags, followers, a comment and assign it to a member of your team or directly to a Asana workspace. Asana helps you coordinate all the work your team does together. So everyone knows what needs to get done, who is responsible for doing it, and when it is due.',
      blurb: 'Create an Asana card directly from a Hootsuite social message',
      categories: ['Collaboration', 'Customer Service'],
      tags: ['plugin', 'Asana', 'brand monitoring'],
      price: '$5.99',
      features: [
        'Create an Asana task directly with image attachments from a social media posted message or scheduled message.',
        'Add custom tags, collaborators, comments, due date and assign to a member of your team.',
        'Original message link is automatically added to the new Asana task.',
      ],
    },
    {
      primaryColor: '#508EF5',
      name: {
        lowerCase: 'googletranslate',
        titleCase: 'Google Translate',
      },
      isVisible: true,
      hasAuth: false,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Plugin'],
      description:
        'Communicate fluently with customers around the world through your social networks. Google Translate integration enables you to translate incoming queries, then respond in your language and see your message translated instantly. Good communication knows no boundaries.',
      blurb: 'Translate a Hootsuite social message without leaving Hootsuite',
      categories: ['Customer Support & Engagement', 'Retail & CPG'],
      tags: ['plugin', 'google', 'translate', 'translation'],
      price: '$2.99',
      features: [
        'Translate any social message from within Hootsuite',
        'Automatically detect the language of the social message or change the source and output language manually',
        'Reply to the social message using the Hootsuite Composer in the source or output language',
      ],
    },
    {
      primaryColor: '#008FE3',
      name: {
        lowerCase: 'trello',
        titleCase: 'Trello',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Plugin'],
      description:
        'Trello for Hootsuite allows you to select a social media post, add labels, members, a comment and send it directly to a Trello list. Trusted by millions of people from all over the world, Trello is the easy, free, flexible, and visual way to manage your projects and organize anything.',
      blurb: 'CCreate a Trello card directly from a Hootsuite social message',
      categories: ['Collaboration', 'Customer Service'],
      tags: ['plugin', 'trello', 'brand monitoring'],
      price: '$5.99',
      features: [
        'Create a Trello card directly from a social media posted message',
        'Add your custom labels, members of each Trello board and due date and comment',
        'Images and original message link are automatically added to the new Trello card',
      ],
    },
    {
      primaryColor: '#4A154B',
      name: {
        lowerCase: 'slack',
        titleCase: 'Slack',
      },
      isVisible: true,
      hasAuth: true,
      hasHsAuth: false,
      needsCookies: false,
      components: ['App Plugin'],
      description: `Select a social media post, add a sentiment or comment, and send or schedule it directly to a Slack Channel, user, or group in your workspace. Slack integration enhances your team's ability to collaborate on social strategy and campaigns, and analyze results and responses.`,
      blurb: 'Create a Slack message directly from a Hootsuite social message',
      categories: ['Collaboration & Project Management', 'Featured'],
      tags: ['plugin', 'Slack', 'brand monitoring'],
      price: '$5.99',
      youtubeEmbedCode: 'oGX3z8J_zME',
      features: [
        'Send social media posts directly to a Slack Channel, User, or Group',
        'Capture relevant social post information for each message',
        'Schedule when to post in Slack to maximize the effectiveness of the message',
        'Add a custom comment along with each social post',
      ],
      privacy: {
        dataItemsStored: {
          Slack: ['ID', 'Access Token'],
        },
      },
    },
  ],
}

export default hootsuite
