import blog from '../../../../blog'
import Post from './Post'
import styled from 'styled-components'

const Title = styled.h1`
  color: grey;
  margin-bottom: 40px;
`

const Updates = () => {
  return (
    <div>
      <Title>Updates</Title>
      {blog.posts.map((post, i) => (
        <Post key={i} post={post} />
      ))}
    </div>
  )
}

export default Updates
