import MondayAIImageCreatorHowToUse from './AIImageCreator'
import MondayHootsuiteHowToUse from './Hootsuite'

const CustomMondayHowToUse = ({ app }) => {
  return (
    <div>
      {app.name.lowerCase === 'ai-image-creator' && (
        <MondayAIImageCreatorHowToUse app={app} />
      )}
      {app.name.lowerCase === 'hootsuite' && (
        <MondayHootsuiteHowToUse app={app} />
      )}
    </div>
  )
}

export default CustomMondayHowToUse
