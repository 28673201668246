import { Alert } from '@mui/material'
import React from 'react'
import { ExternalLink } from 'components/ExternalLink'

const DeprecationNotice = () => {
  return (
    <div>
      <Alert severity={'error'} sx={{ mb: 2 }}>
        Google My Business - Business Messages is being deprecated July 15,
        2024.{' '}
        <ExternalLink
          underline={'true'}
          color={'grey'}
          href={
            'https://jmoneyapps.com/hootsuite/user-guide/gmb-business-messages#deprecation-plan'
          }
        >
          Read more on this page in the Deprecation Plan section
        </ExternalLink>
        .
      </Alert>
    </div>
  )
}

export default DeprecationNotice
