import { Card, CardContent, Typography } from '@material-ui/core'
import styled from 'styled-components'
import AutoMapImg from './mn-hootsuite-auto-map.png'
import HsAuth from './mn-hootsuite-hs-auth.png'
import MnAuth from './mn-hootsuite-mn-auth.png'
import MnAppStore from './mn-hootsuite-mn-app-store.png'
import { ExternalLink } from 'components/ExternalLink'

const Num = styled.span`
  font-weight: bold;
`

const CustomImg = styled.img`
  max-height: 300px;
  margin-right: 20px;
  border-radius: 5px;
`

const HootsuiteInstallation = () => {
  return (
    <div>
      <Card id={'install'} style={{ marginTop: '80px' }}>
        <CardContent>
          <Typography
            style={{ fontWeight: '700' }}
            color="textSecondary"
            gutterBottom
            variant="h1"
          >
            Installation Guide
          </Typography>
          <Card style={{ margin: '20px 0px', padding: '10px', width: '660px' }}>
            <iframe
              style={{ borderRadius: '5px' }}
              class="vidyard_iframe"
              title="Monday.com Onboarding"
              src="//play.vidyard.com/zU7Awx7g4U9NKdMgaWUmFj.html?"
              width="640"
              height="360"
              frameborder="0"
              allowtransparency="true"
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Card>
          <Card style={{ margin: '20px 0', padding: '10px' }}>
            <div style={{ margin: '10px' }}>
              <div>
                <Num>1.</Num> Navigate to monday.com’s marketplace.
              </div>
              <div>
                <CustomImg
                  src={MnAppStore}
                  alt={'monday.com app store listing'}
                />
              </div>
              <div>
                <Num>2.</Num> Click on <Num>“Get Started.”</Num>
              </div>
              <div>
                <Num>3.</Num> Login to your monday.com environment.
              </div>
              <div>
                <Num>4. </Num>You need to install the Hootsuite app in your
                monday.com instance. Start the set-up of the app.
              </div>
              <div style={{ marginLeft: '20px' }}>
                1.Grant Hootsuite permissions to your Monday.com instance and
                follow the authorization process.
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CustomImg src={HsAuth} alt={'Hootsuite auth'} />
                  <CustomImg src={MnAuth} alt={'monday.com auth'} />
                </div>
              </div>
              <div>
                <Num>5.</Num> When this is completed, Hootsuite will be a part
                of your monday account. Hurray!
              </div>
              <div>
                <Num>6.</Num> Now, you need to{' '}
                <Num>configure your Hootsuite board.</Num>
              </div>
              <div style={{ marginLeft: '20px' }}>
                1. Use the monday auto-mapping wizard when prompted.
                <div>
                  <CustomImg src={AutoMapImg} alt="auto mapper" />
                </div>
              </div>
              <div style={{ marginLeft: '20px' }}>
                2. Validate the monday.com "triggers" setup for the Hootsuite
                app.
              </div>
              <div style={{ margin: '10px 0' }}>OPTIONAL</div>
              <div style={{ margin: '10px 0' }}>
                If you'd like to send a social post from Hootsuite's dashboard
                to monday.com, check out the{' '}
                <ExternalLink
                  href={'https://apps.hootsuite.com/apps/monday'}
                  underline={'true'}
                >
                  monday.com for Hootsuite app
                </ExternalLink>{' '}
                in Hootsuite's app directory! Please note that this is a
                separate integration from the one you have just installed. The
                workflows are not dependent on each other.
              </div>
            </div>
          </Card>
        </CardContent>
      </Card>
    </div>
  )
}

export default HootsuiteInstallation
