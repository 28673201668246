import GenericSection from '../../../../../GenericSection'
import Login from './Login'

const AuthFlow = ({ app }) => (
  <div>
    {app.hasAuth && (
      <div>
        <GenericSection
          sectionText={`You will be taken to a screen with a link to login to the app. Follow the authorization process.`}
        />
        <Login app={app} />
      </div>
    )}
  </div>
)

export default AuthFlow
