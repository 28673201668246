import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  title: {
    color: 'white',
    letterSpacing: '7px',
    fontWeight: 'bold',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}))

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const LogoImg = styled.img`
  margin-right: 10px;
`

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props

  const classes = useStyles()

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Link to="/">
          <LogoContainer>
            <LogoImg
              alt={'J Money Apps Logo'}
              src={'/images/logos/logo.svg'}
              style={{ width: '40px' }}
            />
            <Typography className={classes.title} variant="h5">
              J MONEY APPS
            </Typography>
          </LogoContainer>
        </Link>
        <div className={classes.flexGrow} />
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
}

export default Topbar
