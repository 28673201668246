import styled from 'styled-components'
import ContentBody from './ContentBody'
import PluginBody from './PluginBody'
import StreamBody from './StreamBody'

const Container = styled.div`
  min-height: 150px;
  padding: 10px;
`

const SettingsBody = ({ app }) => {
  return (
    <div>
      <Container>
        {app.components[0] === 'Content Source' && <ContentBody app={app} />}
        {app.components[0] === 'App Stream' && <StreamBody app={app} />}
        {app.components[0] === 'App Plugin' && <PluginBody app={app} />}
      </Container>
    </div>
  )
}

export default SettingsBody
