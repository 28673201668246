import { Navigate, Route, Routes } from 'react-router-dom'
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts'
import { NotFound as NotFoundView, UserGuide as UserGuideView } from './views'
import config from './config'
import { getParameterByName } from './utils'
import MondayDomainOwnership from 'views/UserGuide/components/MondayDomainOwnership'

const latestApp = config.hootsuite.apps.find((a) => a.isVisible)?.name.lowerCase
const hideSidebar = getParameterByName('hidesidebar')
const Layout = hideSidebar ? MinimalLayout : MainLayout

const AllRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<Navigate replace to={`/hootsuite/user-guide/${latestApp}`} />}
      />
      <Route
        exact
        path="/privacy"
        element={<Navigate replace to={`/hootsuite/privacy`} />}
      />
      <Route
        element={
          <Layout>
            <UserGuideView />
          </Layout>
        }
      >
        <Route exact path="/:namespace/user-guide/:vendorName" />
        <Route exact path="/user-guide/:vendorName" />
        <Route exact path="/updates" />
        <Route exact path="/updates/:slug" />
        <Route exact path="/:namespace/privacy" />
      </Route>
      <Route
        exact
        path="/monday/user-guide/:vendorName/monday-app-association-json"
        element={<MondayDomainOwnership />}
      />
      <Route
        path={'*'}
        exact
        element={<NotFoundView layout={MinimalLayout} />}
      />
    </Routes>
  )
}

export default AllRoutes
