import styled from 'styled-components'
import Category from './Category'
import { startOfToday, differenceInCalendarDays } from 'date-fns'

const Container = styled.article``

const Header = styled.div`
  display: flex;
  align-items: stretch;
`

const HeaderLeft = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
  opacity: ${(p) => p.opacity}%;
  box-shadow: ${(p) => `0 0 3px ${p.boxShadow}px #e782676e`};
  margin: 5px 10px;
  z-index: 10;
`

const DateText = styled.p`
  color: grey;
`

const Body = styled.div`
  display: flex;
`

const BodyLeft = styled.div`
  width: 3px;
  background: lightgrey;
  margin: 0 13px;
`

const BodyRight = styled.div`
  padding-bottom: 30px;
`

const Title = styled.h2`
  margin: 10px 0;
`

const Categories = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const TextContainer = styled.div`
  color: grey;
`

const Post = ({ post }) => {
  const calcOpacity = (date) => {
    const result = differenceInCalendarDays(new Date(date), startOfToday())
    const opacity = Math.max(100 + result, 0) + 20
    return opacity
  }

  const calcBoxShadowDistance = (date) => {
    const result = differenceInCalendarDays(new Date(date), startOfToday())
    const distance = Math.max(8 + result, 0)
    return distance
  }

  return (
    <Container>
      <Header>
        <HeaderLeft
          opacity={calcOpacity(post.date)}
          boxShadow={calcBoxShadowDistance(post.date)}
        />
        <DateText>{post.date}</DateText>
      </Header>
      <Body>
        <BodyLeft></BodyLeft>
        <BodyRight>
          <Title>{post.title}</Title>
          <Categories>
            <Category app={post.app} namespace={post?.app?.namespace}>
              {post.app}
            </Category>
            {post.categories.map((c, i) => (
              <Category category={c} key={i} />
            ))}
          </Categories>
          <TextContainer>{post.text}</TextContainer>
        </BodyRight>
      </Body>
    </Container>
  )
}

export default Post
