import { Button } from '@material-ui/core'
import { Alert } from '@mui/material'
import GenericSection from '../../../../../GenericSection'
import { Content } from '../../../../../HsDashboard'
import HsStream from './HSStream'
import MoreHorizIcon from '@material-ui/icons/MoreHorizOutlined'
import styled from '@emotion/styled'
import PulsingBadge from 'components/PulsingBadge'

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
`

const RenderPluginIntro = ({ app }) => {
  return (
    <div>
      <p style={{ marginBottom: '10px' }}>
        {`Now that the app is installed, you can navigate to an existing Hootsuite stream, click the ellipsis on a post and select Send to ${app.name.titleCase}.`}
      </p>
      <Alert
        sx={{ display: 'flex', alignItems: 'center' }}
        severity="info"
        variant="outlined"
      >
        <AlertContainer>
          Click on the{' '}
          <PulsingBadge>
            <Button style={{ margin: '0 5px' }} size="small" variant="outlined">
              <MoreHorizIcon />
            </Button>
          </PulsingBadge>{' '}
          below to see where to trigger the app from.
        </AlertContainer>
      </Alert>
      <HsStream app={app} />
    </div>
  )
}

const RenderStreamIntro = ({ app }) => {
  const namespace = 'hootsuite'
  return (
    <div>
      <GenericSection
        sectionText={`Now that the app is installed, if you are not already there, navigate to the stream that the ${app.name.titleCase} app was added to.`}
        sectionImageUrl={`/images/${namespace}/apps/${app.name.lowerCase}/hs-${app.name.lowerCase}-screenshot-1.jpg`}
      />
    </div>
  )
}

const RenderContentIntro = ({ app }) => {
  return (
    <div>
      <GenericSection
        sectionText={`Now that the app is installed, if you are not already there, navigate to the ${app.name.titleCase} content source.  The app can be found by going to the Hootsuite Publisher, then click the "Content" tab.  Find the ${app.name.titleCase} app on the sidebar.`}
      />
    </div>
  )
}

const UsageIntro = ({ app }) => (
  <div>
    {app.components[0] === 'App Plugin' && <RenderPluginIntro app={app} />}
    {app.components[0] === 'App Stream' && <RenderStreamIntro app={app} />}
    {app.components[0] === 'Content Source' && (
      <div>
        <RenderContentIntro app={app} />
        <Content app={app} />
      </div>
    )}
  </div>
)

export default UsageIntro
