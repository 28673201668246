import TermsOfServiceTemplate from '../../../../../../../views/UserGuide/components/TermsOfService'
import MondayTermsOfService from './MondayTermsOfService'

const TermsOfService = ({ app }) => {
  return (
    <div id={'terms'}>
      {app.name.lowerCase === 'hootsuite' ||
      app.name.lowerCase === 'dribbble' ||
      app.name.lowerCase === 'ai-image-creator' ? (
        <MondayTermsOfService app={app} />
      ) : (
        <TermsOfServiceTemplate app={app} />
      )}
    </div>
  )
}

export default TermsOfService
