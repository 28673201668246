import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    margin: '10px 0',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  media: {
    paddingTop: theme.spacing(2),
    maxHeight: 450,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto',
      borderRadius: '4px',
    },
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
}))

const Overview = (props) => {
  const { className, app, ...rest } = props
  const classes = useStyles()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="h1"
            >
              Overview
            </Typography>

            <div>{app.description}</div>
          </Grid>
          <Grid item>
            <div className={classes.media}>
              <img
                style={{ width: '800px', border: '1px solid lightgrey' }}
                alt={'overview'}
                src={`/images/monday/apps/${app.name.lowerCase}/mn-${app.name.lowerCase}-overview.jpg`}
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

Overview.propTypes = {
  className: PropTypes.string,
}

export default Overview
