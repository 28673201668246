import PrivacyTemplate from 'views/UserGuide/components/Privacy'
import MondayPrivacy from './MondayPrivacy'

const Privacy = ({ app }) => {
  return (
    <div id={'privacy'}>
      {app.name.lowerCase === 'hootsuite' ||
      app.name.lowerCase === 'dribbble' ||
      app.name.lowerCase === 'ai-image-creator' ? (
        <MondayPrivacy app={app} />
      ) : (
        <PrivacyTemplate app={app} />
      )}
    </div>
  )
}

export default Privacy
