import { Card, CardContent, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { ExternalLink } from 'components/ExternalLink'
import { Img } from 'react-image'
import styled from 'styled-components'
import OverviewImg from '../img/gmb-overview.png'
import Gmb2Img from '../img/gmb-2.png'
import Gmb3Img from '../img/gmb-3.png'
import Gmb4Img from '../img/gmb-4.png'
import Gmb5Img from '../img/gmb-5.png'
import Gmb6Img from '../img/gmb-6.png'
import Gmb7Img from '../img/gmb-7.png'
import Gmb8Img from '../img/gmb-8.png'
import Gmb9Img from '../img/gmb-9.png'
import Gmb10Img from '../img/gmb-10.png'
import Gmb11Img from '../img/gmb-11.png'
import Gmb12Img from '../img/gmb-12.png'
import Gmb13Img from '../img/gmb-13.png'
import Gmb14Img from '../img/gmb-14.png'
import Gmb15Img from '../img/gmb-15.png'

const GuideSection = styled.div`
  display: flex;
`

const H1 = styled.h1``

const H2 = styled.h2`
  font-size: 32px;
  color: grey;
  margin: 10px 0;
`

const H3 = styled.h3`
  margin: 10px 0;
`

const Text = styled.p`
  margin: 10px 0;
`

const B = styled.span`
  font-weight: bold;
`

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    margin: '20px',
    padding: '20px',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  media: {
    paddingTop: theme.spacing(2),
    maxHeight: 450,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto',
    },
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
}))

const GmbGuide = (props) => {
  const { className, app, ...rest } = props
  const classes = useStyles()

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <H1 id={'guide'}>Google My Business - Business Messages</H1>
        <H2>Guide</H2>
        <Divider />
        <CardContent>
          <GuideSection>
            <div>
              <H2>What is Google My Business (GMB) messaging?</H2>
              <Text>
                <ExternalLink href={'https://businessmessages.google/'}>
                  Business Messages
                </ExternalLink>{' '}
                is a mobile conversational channel that combines entry points on
                Google Maps, Search, and brand websites to create rich,
                asynchronous messaging experiences that delight customers and
                drive business results.
              </Text>
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <Img src={OverviewImg} width={'300px'} />
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>GMB messaging in Hootsuite</H2>
              <H3>Meet customers where they are </H3>
              <Text>
                Message your Google My Business customers directly in Hootsuite.
                Now you can bring your chats into one location right into a
                Hootsuite Stream.
              </Text>
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <Img src={Gmb2Img} width={'300px'} />
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>Verified Location</H2>
              <Text>
                Google my business requires you to have a{' '}
                <B>verified location</B> associated with your physical business.
                Physical location verification takes place in the Google My
                Business site. For more information see this{' '}
                <ExternalLink
                  href={
                    'https://support.google.com/business/answer/7107242?hl=en'
                  }
                >
                  Google Support page.
                </ExternalLink>
              </Text>
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <div>
              <H2>Installation</H2>
              <Text>
                From your Hootsuite dashboard, click on the Profile icon and
                select "Install Hootsuite apps" to navigate to the Hootsuite App
                Directory.
              </Text>
              <Text>
                Once the App Directory loads search for "Google My Business -
                Business Messages" in All Apps and click Install.
              </Text>
              <Text>
                After clicking Install, a new window will open where you can
                select to create a new tab or add a Google My Business -
                Business Messages stream to an existing Hootsuite tab.
              </Text>
              <Img src={Gmb3Img} width={'300px'} />
            </div>
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>Setup</H2>
              <Text>
                In order to verify your ownership over the account Google
                requires few steps to securely give you access to the chatting
                features.
              </Text>
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <div>
              <h4>1. Brand setup:</h4>
              <Text>
                You create a brand to represent the business and an agent so
                that consumers can conversationally connect with them.
              </Text>
              <h4>2. Agent setup:</h4>
              <Text>
                An agent is a conversational representation of a brand—the
                conversational entity that users interact with.
              </Text>
              <h4>3. Location setup:</h4>
              <Text>
                A brand or a business can have multiple locations, and each
                agent can be assigned to a different location.
              </Text>
            </div>
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>Brand Setup</H2>
              <H3>
                Google my business requires you to have a brand that represents
                your business.
              </H3>
              <Text>
                Each business can be represented by a brand, and can have many
                locations.
              </Text>
              <Img src={Gmb4Img} width={'300px'} />
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <div>
              <Text>
                To add your brand, click on the <B>“add brand”</B> button.
              </Text>
              <Text>
                <B>*IMPORTANT*</B> Your brand name must match the name of your
                business
              </Text>
              <Img src={Gmb5Img} width={'300px'} />
            </div>
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>Agent Setup</H2>
              <H3>
                Google my business requires you to have an agent associated with
                each business.
              </H3>
              <Text>
                Your name, email and website on your google dashboard must match
                the information you are providing here.
              </Text>
              <Text>
                Every agent needs a privacy policy linked, this should be your
                policy, not from Google’s side.
              </Text>
              <Text>
                After adding the agent name and privacy policy, you need to
                verify and launch the agent.This must be done before you can
                start chatting with your customers.
              </Text>
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <div>
              <Img src={Gmb6Img} width={'300px'} />
            </div>
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>Conversation Defaults</H2>

              <Text>
                These settings will show up in the customer's mobile messaging
                app and cannot be changed at this time
              </Text>
              <Img src={Gmb7Img} width={'300px'} />
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <div>
              <Img src={Gmb8Img} width={'300px'} />
            </div>
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>Location Setup</H2>
              <Text>
                Pick an agent associated with the location then click create.
                The name and address can be anything that would help you
                identify the location.
              </Text>
              <Text>
                Use the{' '}
                <ExternalLink
                  href={
                    'https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder'
                  }
                >
                  Place ID Finder
                </ExternalLink>{' '}
                to find your Place ID.
              </Text>
              <Img src={Gmb9Img} width={'300px'} />
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <div>
              <Img src={Gmb10Img} width={'300px'} />
            </div>
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>Location Verification</H2>
              <Text>
                Once you Have a location setup, it’s time to Verify it and
                launch it.
              </Text>
              <Text>
                The Most common reason for this step to error out is a mismatch
                of information between your profile with Google and the
                information you added here.
              </Text>
              <Text>
                Another thing that could fail the verification process would be
                if another colleague of yours added the business to their
                account on Hootsuite.In that case contact us.
              </Text>
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <div>
              <Img src={Gmb11Img} width={'300px'} />
            </div>
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>Messaging</H2>
              <H3>The life of a business messaging conversation</H3>
              <Text>
                After you register with Business Messages and create an agent
                for your brand, users can send messages to your brand or your
                brand's locations through your agent. We have seen a delay of
                the launch of this step to up to 24 hours.
              </Text>
              <Text>
                When a user searches and locates a brand that you manage, the
                results include a message button.
              </Text>
              <Text>
                When the user taps the Message button, it opens a conversation
                with your brand's agent on their device, and the agent greets
                the user with a welcome message.
              </Text>
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <div>
              <Img src={Gmb12Img} width={'300px'} />
            </div>
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>Responding</H2>

              <Text>
                When the user sends a message, you can see that message in a
                hootsuite stream. You can have multiple streams to multiple
                businesses that you manage. You can also add multiple streams
                for different locations associated with the same business.
              </Text>
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <div>
              <Img src={Gmb13Img} width={'300px'} />
              <Text>
                To respond to a message, click on the message you wish to
                respond to, the simple start conversing directly with your
                customers.
              </Text>
            </div>
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>7 Day Window</H2>

              <Text>
                Upon receiving a message from a customer, the user is able to
                respond back up to 7 days. After that, the messages are deleted.
              </Text>
              <Img src={Gmb14Img} width={'600px'} />
            </div>
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>Messages Format</H2>

              <Text>
                Currently you can send plain text messages. Media (i.e. video
                and pictures) and rich-text are not yet supported.
              </Text>
              <Img src={Gmb15Img} width={'600px'} />
            </div>
          </GuideSection>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <GuideSection>
            <div>
              <H2>Messaging Guidelines</H2>

              <Text>
                The Google My Business (GMB){' '}
                <ExternalLink
                  href={'https://support.google.com/business/answer/7506237'}
                >
                  messaging guidelines.
                </ExternalLink>
              </Text>
            </div>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ margin: '10px' }}
            />
            <div>
              <H2>Requirements</H2>

              <H3>Cookies</H3>
              <Text>
                You will need to have Third-Party Cookies enabled in your
                browser. Note: Safari blocks these by default. Mac instructions
                can be found{' '}
                <ExternalLink
                  href={
                    'https://support.apple.com/en-ca/guide/safari/sfri11471/mac'
                  }
                >
                  here
                </ExternalLink>
                .
              </Text>
              <H3>Hootsuite account:</H3>
              <Text>
                <ExternalLink href={'https://hootsuite.com/plans/'}>
                  Hootsuite account plans
                </ExternalLink>
              </Text>
              <H3>Google My Business account:</H3>
              <Text>
                <ExternalLink href={'https://www.google.com/business/'}>
                  Google My Business
                </ExternalLink>{' '}
                allows you to engage with customers on Google.
              </Text>
              <Text>
                To learn about how it works:{' '}
                <ExternalLink
                  href={
                    'https://support.google.com/business/answer/9114771?co=GENIE.Platform%3DAndroid&hl=en'
                  }
                >
                  Click here
                </ExternalLink>
              </Text>
            </div>
          </GuideSection>
        </CardContent>
      </Card>
    </div>
  )
}

export default GmbGuide
