/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import {
  Button,
  Chip,
  colors,
  Divider,
  IconButton,
  List,
  ListItem,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { forwardRef, useState } from 'react'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import config from '../../../../../../config'
import SidebarIcon from '../SidebarIcon'
import styled from 'styled-components'
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha'
import SortIcon from '@material-ui/icons/Sort'
import { isActiveApp } from 'utils'

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SortButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Title = styled.div`
  font-family: Roboto, sans-serif;
  color: grey;
  margin: 10px 0 20px 0;
`

const AppNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  sortIcon: {
    color: colors.grey[400],
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

const sortAndFilterApps = ({ apps, sort }) => {
  const filteredApps = apps.filter((a) => a.isVisible)
  if (sort[0] === 'a') {
    if (sort[1]) {
      return filteredApps.sort((a, b) =>
        a.name.lowerCase.localeCompare(b.name.lowerCase)
      )
    } else {
      return filteredApps.sort((a, b) =>
        b.name.lowerCase.localeCompare(a.name.lowerCase)
      )
    }
  } else if (sort[0] === 'new') {
    return sort[1] ? filteredApps : filteredApps.reverse()
  }
}

const HootsuiteSidebarNav = ({ className, vendorConfig, ...rest }) => {
  const location = useLocation()
  const namespace = 'hootsuite'
  const classes = useStyles()
  const [sort, setSort] = useState(['new', true])
  const appName = vendorConfig?.name?.lowerCase
  const isActive = isActiveApp({ appName, location })

  const apps = sortAndFilterApps({ apps: config.hootsuite.apps, sort })

  const handleSortClick = (type) => {
    setSort([type, !sort[1]])
  }

  return (
    <div>
      <List {...rest} className={clsx(classes.root, className)}>
        <Header>
          <Title>Hootsuite Apps</Title>
          <SortButtonsContainer>
            <Tooltip title={'Sort from A - Z'}>
              <IconButton onClick={() => handleSortClick('a')} size={'small'}>
                <SortByAlphaIcon className={classes.sortIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Sort from Newest to Oldest'}>
              <IconButton onClick={() => handleSortClick('new')} size={'small'}>
                <SortIcon className={classes.sortIcon} />
              </IconButton>
            </Tooltip>
          </SortButtonsContainer>
        </Header>
        {apps.map((app, i) => (
          <ListItem className={classes.item} disableGutters key={i}>
            <Button
              // activeClassName={classes.active}
              className={clsx(
                classes.button,
                isActive ? classes.active : undefined
              )}
              component={CustomRouterLink}
              to={`/${namespace}/user-guide/${app.name.lowerCase}`}
            >
              <div className={classes.icon}>
                <SidebarIcon
                  imgSrc={`/images/${namespace}/apps/${app.name.lowerCase}/${app.name.lowerCase}-256.png`}
                  vendorName={app.name.lowerCase}
                />
              </div>
              <AppNameContainer>
                {app.name.titleCase}
                {app.isNew && (
                  <Chip
                    variant={'outlined'}
                    size={'small'}
                    label={`NEW!`}
                    style={{
                      borderRadius: '4px',
                      marginLeft: '5px',
                      padding: '0 5px',
                      color: 'primary',
                    }}
                  />
                )}
              </AppNameContainer>
            </Button>
          </ListItem>
        ))}
      </List>
      <Divider style={{ margin: '40px 0 20px 0' }} />
      {/* <OtherNav namespace={namespace} /> */}
    </div>
  )
}

HootsuiteSidebarNav.propTypes = {
  className: PropTypes.string,
}

export { HootsuiteSidebarNav }
