import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ProductCard } from './components'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles((theme) => ({
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  root: {
    height: '100%',
    margin: '10px 0',
  },
  content: {
    alignItems: 'center',
  },
  media: {
    paddingTop: theme.spacing(2),
    height: 400,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto',
    },
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
  cookiesWarning: {
    marginTop: theme.spacing(2),
  },
  cookiesWarningContent: {
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    marginRight: theme.spacing(1),
  },
}))

const Title = styled.div`
  margin: 10px;
`

const Requirements = ({ className, app, ...rest }) => {
  const classes = useStyles()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.root}>
          <div className={classes.content}>
            <Title>
              <Typography
                variant={'h1'}
                color={'textSecondary'}
                style={{ fontWeight: '700' }}
              >
                Requirements
              </Typography>
            </Title>
            <Grid container spacing={2}>
              <Grid item key={'hs'} lg={6} md={6} xs={12}>
                <ProductCard app={app} isHs />
              </Grid>
              <Grid item key={app.name.lowerCase} lg={6} md={6} xs={12}>
                <ProductCard app={app} />
              </Grid>
            </Grid>
            {app.needsCookies && (
              <Card className={classes.cookiesWarning}>
                <CardContent className={classes.cookiesWarningContent}>
                  <WarningIcon className={classes.warningIcon} />

                  <Typography>
                    You will need to have Third-Party Cookies enabled in your
                    browser. Note: Safari blocks these by default. Mac
                    instructions can be found{' '}
                    <a
                      href="https://support.apple.com/en-ca/guide/safari/sfri11471/mac"
                      target={'_blank'}
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .
                  </Typography>
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

Requirements.propTypes = {
  className: PropTypes.string,
}

export default Requirements
