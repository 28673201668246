import PropTypes from 'prop-types'

const SidebarIcon = ({ vendorName, imgSrc }) => (
  <img
    alt={`${vendorName} logo`}
    style={{ width: '20px', borderRadius: '2px' }}
    src={imgSrc}
  />
)

SidebarIcon.propTypes = {
  vendorName: PropTypes.string,
  imgSrc: PropTypes.string,
}

SidebarIcon.defaultProps = {
  vendorName: '',
  imgSrc: '',
}

export default SidebarIcon
