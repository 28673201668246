import { Card, CardContent, Grid, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import config from '../../../../../../../config'
import styled from 'styled-components'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const HelpText = styled.div`
  color: grey;
  display: flex;
  align-items: center;
  margin: 10px 0;
`

const Link = styled.a`
  margin-left: 5px;
`

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    margin: '80px 0',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  media: {
    paddingTop: theme.spacing(2),
    height: 400,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto',
    },
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
  accordion: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '90%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionSummary: {
    background: '#d3d3d354',
    borderLeft: '4px solid slategrey',
    borderRadius: 'inherit',
    padding: '10px',
  },
}))

const Demo = ({ className, app, ...rest }) => {
  const namespace = 'monday'
  const feedbackUrl = config[namespace].feedbackUrl
  const classes = useStyles()

  return (
    <section>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                id={'demo'}
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="h1"
              >
                Book a Demo
              </Typography>

              <Typography variant={'h5'}>
                Want to see how the app works before installing?
              </Typography>
              <HelpText>
                <Button variant={'outlined'}>
                  <OpenInNewIcon />
                  <Link
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    href={feedbackUrl}
                  >
                    Contact to book a demo
                  </Link>
                </Button>
              </HelpText>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </section>
  )
}

export default Demo
