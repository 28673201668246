import PrivacyTemplate from 'views/UserGuide/components/Privacy'
import SlackPrivacy from './SlackPrivacy'

const Privacy = ({ namespace, app }) => {
  return (
    <div id={'privacy'}>
      {app.name.lowerCase === 'slack' ? (
        <SlackPrivacy app={app} namespace={'hootsuite'} />
      ) : (
        <PrivacyTemplate app={app} />
      )}
    </div>
  )
}

export default Privacy
