import styled from 'styled-components'

// const HsDashContainer = styled.div`
//   overflow: hidden;
// `
// const Topbar = styled.img`
//   max-width: 100%;
// `
// const LowerDashboard = styled.div`
//   display: flex;
// `

// const Sidebar = styled.img`
//   width: 3%;
//   height: 100%;
// `

// const MultipleImagesContainer = styled.div``

// const AppStreams = styled.img`
//   width: 100%;
//   height: 100%;
// `

// const WhiteHaze = styled.div`
//   position: absolute;
//   width: 700px;
//   height: 100%;
//   top: 25%;
//   left: 25%;
//   background: white;
//   opacity: 70%;
// `

// const PluginOverlay = styled.img`
//   position: absolute;
//   width: 600px;
//   top: 50%;
//   left: 50%;
// `

const PluginImgContainer = styled.div`
  margin: 20px;
  background: #f5f5f5;
`

const PluginImg = styled.img`
  width: 75%;
  border: 2px solid #153058;
  opacity: 1;
`

const Plugin = ({ app }) => {
  const namespace = 'hootsuite'
  return (
    <PluginImgContainer>
      <PluginImg
        src={`/images/${namespace}/apps/${app.name.lowerCase}/hs-${app.name.lowerCase}-screenshot-1.jpg`}
        alt={`${app.name.lowerCase} screenshot`}
      />
    </PluginImgContainer>
    // <HsDashContainer>
    //   <Topbar
    //     src={'/images/user-guide/hs-dash-top.png'}
    //     alt={'Hootsuite topbar'}
    //   />
    //   <LowerDashboard>
    //     <Sidebar
    //       src={'/images/user-guide/hs-dash-sidebar.png'}
    //       alt={'Hootsuite sidebar'}
    //     />
    //     <MultipleImagesContainer>
    //       <AppStreams
    //         alt={`${app.name.lowerCase} Screenshot 1`}
    //         src={`/images/user-guide/hs-dash-generic-streams.png`}
    //       />
    //       <WhiteHaze />
    //       <PluginOverlay src={'/images/apps/wrike/wrike-screenshot-1.png'} />
    //     </MultipleImagesContainer>
    //   </LowerDashboard>
    // </HsDashContainer>
  )
}

export { Plugin }
