/* Possible Categories: New App, Maintenance, UI, New Feature, Bux Fix */

const blog = {
  posts: [
    {
      namespace: 'hootsuite',
      date: 'May 28, 2024',
      title: 'Instagram Grid Maintenance',
      text: 'App maintenance.',
      app: 'instagram-grid',
      categories: ['Maintenance'],
    },
    {
      namespace: 'monday',
      date: 'May 27, 2024',
      title: 'Added LinkedIn and video support',
      text: `The integration now supports LinkedIn accounts.  Use the entire workflow with Instagram Business. Also video uploading is supported, restrictions apply depending on the social network.  See more information on the specific restrictions: https://developer.hootsuite.com/docs/uploading-media#media-restrictions`,
      app: 'hootsuite',
      categories: ['New Feature'],
    },
    {
      namespace: 'hootsuite',
      date: 'May 8, 2024',
      title: 'DALL-E 3 now being used!',
      text: `The DALL-E for Hootsuite integration is now using OpenAI's DALL-E 3 instead of DALL-E 2.  DALL·E 3 understands significantly more nuance and detail than the previous systems, allowing you to easily translate your ideas into exceptionally accurate images.`,
      app: 'dall-e',
      categories: ['New Feature'],
    },
    {
      namespace: 'monday',
      date: 'May 1, 2024',
      title: 'Added Instagram Business support',
      text: `The integration now supports Instagram Business accounts.  Use the entire workflow with Instagram Business.`,
      app: 'hootsuite',
      categories: ['New Feature'],
    },
    {
      namespace: 'hootsuite',
      date: 'April 23, 2024',
      title: 'Canva Maintenance',
      text: 'App maintenance.',
      app: 'canva',
      categories: ['Maintenance'],
    },
    {
      namespace: 'monday',
      date: 'April 1, 2024',
      title: 'Added first-time wizard guide and better error reporting',
      text: `Now, the first time the app is used, a wizard guide will appear, showing ways to navigate the integration.  Also more errors that occur when using the monday.com Main Table tab with the integration will be explained in the monday.com item's update.`,
      app: 'hootsuite',
      categories: ['Maintenance'],
    },
    {
      namespace: 'monday',
      date: 'March 22, 2024',
      title: 'User Guide update',
      text: 'Added how to use and troubleshooting steps including links to video.',
      app: 'hootsuite',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'February 16, 2024',
      title: 'Google My Business - Business Messages Maintenance',
      text: 'App maintenance.',
      app: 'gmb-business-messages',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'February 14, 2024',
      title: 'monday.com for Hootsuite Maintenance',
      text: 'App maintenance.',
      app: 'monday',
      categories: ['Maintenance'],
    },
    {
      namespace: 'monday',
      date: 'February 6, 2024',
      title: 'Hootsuite for monday.com Maintenance',
      text: 'App maintenance.',
      app: 'hootsuite',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'November 30, 2023',
      title: 'Instagram Grid cleaner support for Instagram Business',
      text: 'The Instagram Grid for Hootsuite app now no longer requires 2 logins to fetch both Instagram Business and Instagram Personal profiles.',
      app: 'instagram-grid',
      categories: ['New Feature'],
    },
    {
      namespace: 'hootsuite',
      date: 'November 22, 2023',
      title: 'Dall-E app maintenance.',
      text: 'App maintenance.',
      app: 'dall-e',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'November 1, 2023',
      title: 'Google My Business - Business Messages Maintenance',
      text: 'App maintenance.',
      app: 'gmb-business-messages',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'October 3, 2023',
      title: 'New App: ClickUp for Hootsuite!',
      text: 'Save time with the all-in-one productivity platform that brings teams, tasks, and tools together in one place. The ClickUp for Hootsuite app allows you to send a social media post to ClickUp directly from Hootsuite.',
      app: 'clickup',
      categories: ['New App'],
    },
    {
      namespace: 'monday',
      date: 'September 12, 2023',
      title: 'Hootsuite for monday.com Maintenance',
      text: 'App maintenance.',
      app: 'hootsuite',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'August 31, 2023',
      title: 'All Apps Maintenance',
      text: 'All apps - app maintenance.',
      app: 'j-money-apps',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'July 17, 2023',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'googletranslate',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'July 12, 2023',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'monday',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'July 10, 2023',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'trello',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'July 1, 2023',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'slack',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'June 20, 2023',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'wrike',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'April 11, 2023',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'disqus',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'April 10, 2023',
      title: 'Another New Spotify Feature!',
      text: 'Audiobooks now supported. Navigate using the menu or search for your podcasts within the app.',
      app: 'spotify',
      categories: ['Maintenance', 'New Feature'],
    },
    {
      namespace: 'hootsuite',
      date: 'April 6, 2023',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'strava',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'April 4, 2023',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'miro',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'April 4, 2023',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'figma',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'April 4, 2023',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'dribbble',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'April 4, 2023',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'instagram-grid',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'March 15, 2022',
      title: 'New App: Dall-E for Hootsuite!',
      text: 'DALL·E 2 is a new AI system that can create realistic images and art from a description in natural language.  Create new images directly from Hootsuite and add them to your social messages.',
      app: 'dall-e',
      categories: ['New App'],
    },
    {
      namespace: 'hootsuite',
      date: 'February 23, 2023',
      title: 'New User Login',
      text: 'Use your Airtable login instead of finding your Airtable API Key.',
      app: 'airtable',
      categories: ['New Feature'],
    },
    {
      namespace: 'hootsuite',
      date: 'November 8, 2022',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'trello',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'November 7, 2022',
      title: 'Multiple account support and maintenance',
      text: 'Multiple Disq.us accounts are now supported through separate Hootsuite app streams. App maintenance as well.',
      app: 'disqus',
      categories: ['New Feature, Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'November 7, 2022',
      title: 'Maintenance',
      text: 'App maintenance.',
      app: 'canva',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'October 31, 2022',
      title: 'Updates and Instagram Business support',
      text: 'Added support to send directly to Instagram Business within app. An additional login is required.  Updated the app as well.',
      app: 'instagram-grid',
      categories: ['Maintenance, New Feature'],
    },
    {
      namespace: 'hootsuite',
      date: 'October 13, 2022',
      title: 'New App: Notion for Hootsuite!',
      text: 'One workspace for team collaboration, personal milestones, and everything in between. Notion is for notes, tasks, wikis, and databases. The app blends everyday work apps into one. It serves as an all-in-one workspace for note-taking, task management, and project management. The Notion for Hootsuite app allows you to send a social media post to Notion directly from Hootsuite.',
      app: 'notion',
      categories: ['New App'],
    },
    {
      namespace: 'hootsuite',
      date: 'Aug 25, 2022',
      title: 'Bug Fix and Maintenance',
      text: `Bug fix to allow for hashtags (#) in the Trello card name and app maintenance.`,
      app: 'trello',
      categories: ['Bug Fix', 'Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'June 1, 2022',
      title: 'New App: Wordpress.com for Hootsuite!',
      text: 'View posts, comments, and media from your Wordpress.com site. You can like, approve, respond, mark as spam, or delete messages directly from Hootsuite. WordPress.com is a division of Automattic. It enables users to create websites for various purposes, such as e-commerce, blogs, portfolios, etc.',
      app: 'wordpress',
      categories: ['New App'],
    },
    {
      namespace: 'hootsuite',
      date: 'May 11, 2022',
      title: 'Maintenance',
      text: `App maintenance`,
      app: 'instagram-grid',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'April 30, 2022',
      title: 'Google My Business - Business Messages update',
      text: `App maintenance to location importer and UI.`,
      app: 'gmb',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'April 8, 2022',
      title: 'New Spotify Features!',
      text: 'Multiple Spotify accounts and podcasts now suppotred. Search results include podcasts now too.',
      app: 'spotify',
      categories: ['Maintenance', 'New Features'],
    },
    {
      namespace: 'hootsuite',
      date: 'March 22, 2022',
      title: 'J Money Apps Update',
      text: 'Maintenance',
      app: 'j-money-apps',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'February 16, 2022',
      title: 'Miro maintenance work',
      text: `App maintenance`,
      app: 'miro',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'January 25, 2022',
      title: 'Routes now added to Strava App!',
      text: `Share your running or cycling routes to Hootsuite. Also images are attached to Hootsuite composer when sharing activity or route.`,
      app: 'strava',
      categories: ['New Feature'],
    },
    {
      namespace: 'hootsuite',
      date: 'January 18, 2022',
      title: 'Strava maintenance work',
      text: `App maintenance`,
      app: 'strava',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'January 5, 2022',
      title: 'Miro maintenance work',
      text: `App maintenance`,
      app: 'miro',
      categories: ['Maintenance'],
    },
    {
      namespace: 'hootsuite',
      date: 'January 4, 2022',
      title: 'Google My Business - Business Messages update',
      text: `App maintenance and updated user guide link.`,
      app: 'gmb',
      categories: ['Maintenance', 'Bug Fix'],
    },
    {
      namespace: 'hootsuite',
      date: 'December 17, 2021',
      title: 'More Slack Channels support',
      text: `Unlimited channels (direct messages, groups, connections, channels) supported now, previously only 1000 maximum. Note that more than 1000 channels will slow down the app's performance.  Connections now have their own grouping in the Channel Selector.  Multiple Person DMs now have all member real names, comma separated and listed.`,
      app: 'slack',
      categories: ['New Feature', 'Bug Fix'],
    },
    {
      namespace: 'hootsuite',
      date: 'November 11, 2021',
      title: 'Send to New Hootsuite Composer',
      text: 'Send Canva designs to the new Hootsuite composer.',
      app: 'canva',
      categories: ['New Feature'],
    },
    {
      namespace: 'hootsuite',
      date: 'November 11, 2021',
      title: 'Send to New Hootsuite Composer',
      text: 'Send Spotify content to the new Hootsuite composer.',
      app: 'spotify',
      categories: ['New Feature'],
    },
    {
      namespace: 'monday',
      date: 'November 11, 2021',
      title: 'Auto Mapping and Status Updates',
      text: 'The Hootsuite for monday.com app now updates monday.com items when the Hootsuite post has been sent or deleted from Hootsuite.  Also the app will try to automatically map the required fields when first navigating to the Hootsuite custom view in the monday.com board. Bug fix for Hootsuite login issue.',
      app: 'hootsuite',
      categories: ['New Feature', 'Bug Fix'],
    },
    {
      namespace: 'hootsuite',
      date: 'November 10, 2021',
      title: 'Send to New Hootsuite Composer',
      text: 'Send Miro content to the new Hootsuite composer.',
      app: 'miro',
      categories: ['New Feature'],
    },
    {
      namespace: 'hootsuite',
      date: 'November 1, 2021',
      title: 'New App: Miro for Hootsuite!',
      text: 'Whether you are organizing a hackathon, a thinkathon, event or just want to share your Miro board, the Miro for Hootsuite app allows you to share your Miro content on social media. Engage your audience on social media with progress and results using the online collaborative whiteboard platform, Miro. View your Miro Boards within a Hootsuite Stream or Content Source, attach previews and links to social posts, and share interactive content across your social networks.',
      app: 'miro',
      categories: ['New App'],
    },
    {
      namespace: 'hootsuite',
      date: 'October 14, 2021',
      title: 'Send to New Hootsuite Composer',
      text: 'Send Disq.us posts and replies to the new Hootsuite composer.',
      app: 'disqus',
      categories: ['New Feature'],
    },
    {
      namespace: 'hootsuite',
      date: 'October 14, 2021',
      title: 'Send to New Hootsuite Composer',
      text: 'Send Strava activities and club info to the new Hootsuite composer.  Also removed Races as they have been deprecated from the Strava API.',
      app: 'strava',
      categories: ['New Feature'],
    },
    {
      namespace: 'monday',
      date: 'September 6, 2021',
      title: 'New App: Hootsuite for monday.com!',
      text: 'Streamline your social media campaigns workflows - plan, track and publish all posts from one place. monday.com users will be able to see all of their Hootsuite scheduled posts directly from their Calendar view and change when posts are shared alongside any content or projects available in monday.com.',
      app: 'hootsuite',
      categories: ['New App'],
    },
    {
      date: 'August 29, 2021',
      title: 'Canva templates now include LinkedIn Posts and LinkedIn Stories',
      text: 'Added LinkedIn Posts and LinkedIn Stories template options to the Canva dropdown.',
      app: 'canva',
      categories: ['New Feature'],
    },
    {
      date: 'August 25, 2021',
      title: 'Empty social post bug fix',
      text: 'Fixed bug for empty social post description in Wrike',
      app: 'wrike',
      categories: ['Bug Fix'],
    },
    {
      date: 'August 13, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'slack',
      categories: ['Maintenance'],
    },
    {
      date: 'August 13, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'asana',
      categories: ['Maintenance'],
    },
    {
      date: 'August 13, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'wrike',
      categories: ['Maintenance'],
    },
    {
      date: 'August 12, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'monday',
      categories: ['Maintenance'],
    },
    {
      date: 'August 11, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'basecamp',
      categories: ['Maintenance'],
    },
    {
      date: 'August 11, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'trello',
      categories: ['Maintenance'],
    },
    {
      date: 'August 11, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'airtable',
      categories: ['Maintenance'],
    },
    {
      date: 'August 10, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'googletranslate',
      categories: ['Maintenance'],
    },
    {
      date: 'August 10, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'canva',
      categories: ['Maintenance'],
    },
    {
      date: 'August 9, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'strava',
      categories: ['Maintenance'],
    },
    {
      date: 'August 9, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'disqus',
      categories: ['Maintenance'],
    },
    {
      date: 'August 3, 2021',
      title: 'Maintenance',
      text: 'Maintenance work.',
      app: 'spotify',
      categories: ['Maintenance'],
    },
    {
      date: 'July 13, 2021',
      title: 'Asana Projects Dropdown',
      text: 'Fixed scrolling issue for projects, collaborators, tags and assignees.',
      app: 'asana',
      categories: ['Bug Fix'],
    },
    {
      date: 'June 17, 2021',
      title: 'Slack Username Tagging Fix',
      text: 'Added a space between "@" symbol and the Twitter username to prevent Slack from tagging the post with a matching Slack username.',
      app: 'slack',
      categories: ['Bug Fix'],
    },
    {
      date: 'June 8, 2021',
      title: 'Privacy Policy Move',
      text: 'Moved the general Privacy policy from the left sidebar to the bottom of each app page.  This will allow for more detailed and specific privacy policies.',
      app: 'j-money',
      categories: ['New Feature'],
    },
    {
      date: 'February 23, 2021',
      title: 'New Video Section',
      text: 'Added a Video section below the Installation steps for each app that has a video. Also added videos for Slack, Google My Business - Business Messages and monday.com',
      app: 'j-money',
      categories: ['New Feature'],
    },
    {
      date: 'February 11, 2021',
      title: 'Spotify New Feature',
      text: 'Attaching images for tracks, artists, playlists, albums when sending to the Hootsuite Composer.',
      app: 'spotify',
      categories: ['New Feature'],
    },
    {
      date: 'February 11, 2021',
      title: 'Airtable New Features',
      text: 'Updated login flow to only take in the API Key.  Bases, Tables, Mesasge Field and Attachment Field dropdowns are now auto-populated. Thank you Airtable.com!! Added Long Text, Formula and Rich Text formats as acceptable Message fields.  Added all Message fields plus File field as acceptable Attachment fields.',
      app: 'airtable',
      categories: ['New Feature'],
    },
    {
      date: 'February 10, 2021',
      title: 'Basecamp Empty Message Fix',
      text: 'Fixed issue for Hootsuite message containing no text. Previously, if the social post contained no text (media only), the app would display a blank screen.',
      app: 'basecamp',
      categories: ['Bug Fix'],
    },
    {
      date: 'February 9, 2021',
      title: 'Trello New Look',
      text: 'Added a fresh new look to the Trello for Hootsuite app and addressed a security issue. Also added due dates and image attachments.',
      app: 'trello',
      categories: ['UI', 'New Feature'],
    },
    {
      date: 'January 27, 2021',
      title: 'Google My Business - Business Messages',
      text: 'Embedded the detailed user guide into this site instead of downloading a pdf.  Also added some first time use messages within the app.',
      app: 'gmb-business-messages',
      categories: ['UI'],
    },
    {
      date: 'January 25, 2021',
      title: 'Slack New Look',
      text: 'Added a fresh new look to the Slack for Hootsuite app and addressed a security issue.',
      app: 'slack',
      categories: ['UI'],
    },
    {
      date: 'January 11, 2021',
      title: 'Basecamp New Look',
      text: 'Added a fresh new look to the Basecamp for Hootsuite app and addressed a security issue.',
      app: 'basecamp',
      categories: ['UI'],
    },
    {
      date: 'January 8, 2021',
      title: 'Wrike New Look',
      text: 'Added a fresh new look to the Wrike for Hootsuite app and addressed a security issue.',
      app: 'wrike',
      categories: ['UI'],
    },
    {
      date: 'January 7, 2021',
      title: 'Canva Button 2.0',
      text: 'Enjoy Canvaʼs brand new Editor 2.0 experience. Choose from a wider range of supported design types.',
      app: 'canva',
      categories: ['New Feature'],
    },
    {
      date: 'January 6, 2021',
      title: 'Fixed authentication issue',
      text: 'Fixed an issue with authentication in the app and addressed a security issue.',
      app: 'disqus',
      categories: ['Bug Fix'],
    },
    {
      date: 'December 31, 2020',
      title: 'New app: Google My Business - Business Messages!',
      text: 'Now you can direct-message with your customers in Hootsuite through your Google My Business account. Meet your customers where they are.',
      app: 'gmb-business-messages',
      categories: ['New App'],
    },
    {
      date: 'December 31, 2020',
      title: 'New app: Instagram Grid!',
      text: 'The Instagram Grid app lets you remix your photos and share them with your followers on Instagram directly from Hootsuite.',
      app: 'instagram-grid',
      categories: ['New App'],
    },
    {
      date: 'December 7, 2020',
      title: 'Fixed Boards not showing issue',
      text: 'Previously Boards would only show if they were not in a Folder or a Sub-Item. Now all Boards and Sub-Items are available to assign the Task to.',
      app: 'monday',
      categories: ['Bug Fix'],
    },
    {
      date: 'November 23, 2020',
      title: 'New Privacy Policy',
      text: 'Added a Privacy Policy that encompassess all apps listed on this site.',
      app: 'j-money-apps',
      categories: ['New Feature'],
    },
    {
      date: 'November 9, 2020',
      title: 'monday.com Update',
      text: 'Moved Update section under Columns section, within the scrollable body instead of within the footer.',
      app: 'monday',
      categories: ['UI'],
    },
    {
      date: 'November 3, 2020',
      title: 'Airtable Update',
      text: 'Maintenance work. Updated app primary color.',
      app: 'airtable',
      categories: ['Maintenance'],
    },
    {
      date: 'October 29, 2020',
      title: 'New App - monday.com!',
      text: 'Launched the new monday.com app! monday.com for Hoostuite allows you to create a monday.com task from a social message within Hootsuite.  Supported monday.com columns corresponding to your Board are detected and available to add to your monday.com task. monday.com is a cloud-based Work OS, where Teams create workflow apps in minutes to run their processes, projects, and everyday work.',
      app: 'monday',
      categories: ['New App'],
    },
    {
      date: 'September 10, 2020',
      title: 'Google Translate Update',
      text: 'Completely overhauled the Google Translate app. Added full language names instead of abreviations. Addressed security issues.  Updated UI to match Google Translate and new apps.',
      app: 'googletranslate',
      categories: ['New Feature'],
    },
    {
      date: 'August 25, 2020',
      title: 'Asana Update',
      text: 'Completely overhauled the Asana app.  Addressed security issues. Added multiple image attachments and due date support.  Updated UI to match new apps.',
      app: 'asana',
      categories: ['New Feature'],
    },
    {
      date: 'August 6, 2020',
      title: 'Basecamp Update',
      text: 'Maintenance work.',
      app: 'basecamp',
      categories: ['Maintenance'],
    },
    {
      date: 'August 4, 2020',
      title: 'FAQ section in Canva User Guide',
      text: 'Created an FAQ section in Canva User Guide and added a help guide to access previously made Canva designs from the app.',
      app: 'canva',
      categories: ['New Feature'],
    },
    {
      date: 'July 31, 2020',
      title: 'Wrike Update',
      text: 'Maintenance work',
      app: 'wrike',
      categories: ['Maintenance'],
    },
    {
      date: 'July 31, 2020',
      title: 'J Money Apps Update',
      text: 'Created an updates section that you are reading right now.',
      app: 'j-money-apps',
      categories: ['New Feature'],
    },
  ],
}

export default blog
