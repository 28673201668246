import Installation from '../../GeneralBody/Installation'
import Overview from '../../GeneralBody/Overview'
import Requirements from '../../GeneralBody/Requirements'
import TermsOfService from '../../GeneralBody/TermsOfService'
import Usage from '../../GeneralBody/Usage'
import Privacy from './Privacy'

const SlackBody = ({ app }) => {
  return (
    <div>
      <Overview app={app} />
      <Requirements app={app} />
      <Installation app={app} />
      <Usage app={app} />
      <Privacy app={app} />
      <TermsOfService app={app} namespace={'hootsuite'} />
    </div>
  )
}

export default SlackBody
