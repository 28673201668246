import styled from 'styled-components'

const HsDashContainer = styled.div`
  overflow: hidden;
`
const Topbar = styled.img`
  display: block;
  max-width: 100%;
`
const LowerDashboard = styled.div`
  display: flex;
`

const Sidebar = styled.img`
  width: 23%;
  height: 100%;
`

const ContentSource = styled.img`
  width: 80%;
  height: 100%;
`

const Content = ({ app }) => {
  const namespace = 'hootsuite'
  return (
    <HsDashContainer>
      {app.components.includes('Content Source') && (
        <ContentSource
          alt={`${app.name.lowerCase} Screenshot 1`}
          src={`/images/${namespace}/apps/${app.name.lowerCase}/hs-${app.name.lowerCase}-screenshot-1.jpg`}
        />
      )}
    </HsDashContainer>
  )
}

export { Content }
