import Installation from '../../GeneralBody/Installation'
import Overview from '../../GeneralBody//Overview'
import Requirements from '../../GeneralBody//Requirements'
import Usage from '../../GeneralBody//Usage'
import BrightcoveGuide from './BrightcoveGuide'
import Faq from '../../GeneralBody/Faq'
import Privacy from '../../GeneralBody/Privacy'
import TermsOfService from '../../GeneralBody/TermsOfService'

const BrightcoveBody = ({ namespace, app }) => {
  return (
    <div>
      <Overview app={app} />
      <Requirements app={app} />
      <Installation app={app} />
      <BrightcoveGuide app={app} />
      <Usage app={app} />
      <Faq app={app} />
      <Privacy app={app} namespace={namespace} />
      <TermsOfService app={app} namespace={namespace} />
    </div>
  )
}

export default BrightcoveBody
