import { Card, CardContent, Typography } from '@material-ui/core'
import TriggersIssue from './VideoEmbeds/TriggersIssue'
import { Img } from 'react-image'
import AddToConvoImg from './mn-hootsuite-add-to-conversation.png'
import CustomTriggersImg from './mn-hootsuite-triggers.png'
import ViewUpdatesImg from './mn-hootsuite-view-item-updates.png'
import AttachmentsNotUploading from './VideoEmbeds/AttachmentsNotUploading'
import EntireVideo from './VideoEmbeds/EntireVideo'
import MediaRestrictions from './mn-hs-media-upload-restrictions.png'

const HootsuiteKnownLimitations = () => {
  return (
    <div>
      <Card id={'known-limitations'} style={{ marginTop: '80px' }}>
        <CardContent>
          <Card style={{ margin: '20px 0', padding: '10px' }}>
            <Typography style={{ margin: '10px 0' }} variant={'h2'}>
              Media upload restrictions
            </Typography>
            <div style={{ fontWeight: 'bold' }}>Solution First</div>
            <div style={{ margin: '10px 0' }}>
              Some restrictions apply when uploading media for the various
              social networks supported.
            </div>
            <div>
              <Img
                style={{ marginTop: '10px', borderRadius: '5px' }}
                src={MediaRestrictions}
              />
            </div>
          </Card>

          <Typography
            style={{ fontWeight: '700' }}
            color="textSecondary"
            gutterBottom
            variant="h1"
          >
            Known Limitations
          </Typography>
          <Card style={{ margin: '20px 0', padding: '10px' }}>
            <Typography style={{ margin: '10px 0' }} variant={'h2'}>
              Edit the social message using the Main Table tab
            </Typography>
            <div style={{ fontWeight: 'bold' }}>Solution First</div>
            <div style={{ margin: '10px 0' }}>
              Look to the monday.com item's Updates ("Add to converstion"
              button) for any error communication or updates. If you believe the
              integration is setup already properly and is not behaving in the
              intended manor, there is a good chance some message will appear in
              that Updates section of the item.
            </div>
            <div>
              <Img
                style={{ marginTop: '10px', borderRadius: '5px' }}
                src={AddToConvoImg}
              />
            </div>
            <div>
              <Img
                style={{ marginTop: '10px', borderRadius: '5px' }}
                src={ViewUpdatesImg}
              />
            </div>
            <div style={{ fontWeight: 'bold' }}>Explanation</div>
            <div style={{ margin: '10px 0' }}>
              When editting any of the monday.com fields for the Hootsuite
              social message, the integration does not have access to popup
              visual error / information banners and can only communicate errors
              using the monday.com item's Update section. The best way to be
              informed if an error has occurred in editting, adding attachments,
              etc, is to view the Updates.
            </div>
            <AttachmentsNotUploading />
          </Card>

          <Card style={{ margin: '20px 0', padding: '10px' }}>
            <Typography style={{ margin: '10px 0' }} variant={'h2'}>
              Stale monday.com sessions
            </Typography>
            <div style={{ fontWeight: 'bold' }}>Solution First</div>
            <div style={{ margin: '10px 0' }}>
              If the integration is not working, first refresh the browser. Try
              this once as it is the quickest possible solution, if that doesn't
              help, watch the troubleshooting video below for other possible
              solutions.
            </div>
            <div style={{ fontWeight: 'bold' }}>Explanation</div>
            <div style={{ margin: '10px 0' }}>
              If you stay on the monday.com dashboard but does not interact with
              it, the monday.com session will become stale, for security
              reasons. Some monday.com functionality will work, but the
              integration may not work properly. If the user tries to interact
              with the Main Table tab or the Hootsuite tab, you will be using a
              stale session token. This occurs when you navigate to a different
              browser tab (not monday.com board tab) as well.
            </div>

            <div style={{ margin: '10px 0' }}>
              The way to get a fresh session token is to refresh the browser or
              continuously use the app. So if the user refreshes the browser
              every time they want to interact with the app (either the
              Hootsuite Field Mapper tab or the Main Table tab).
            </div>
            <EntireVideo />
          </Card>

          <Card style={{ margin: '20px 0', padding: '10px' }}>
            <Typography style={{ margin: '10px 0' }} variant={'h2'}>
              Hootsuite integration triggers not setup
            </Typography>
            <div style={{ fontWeight: 'bold' }}>Solution First</div>
            <div style={{ margin: '10px 0' }}>
              Navigate to the Hootsuite integrations's custom triggers setup and
              ensure they are enabled. Possibly remove and re-add them.
            </div>
            <div style={{ margin: '10px 0' }}>
              <TriggersIssue />
            </div>
            <div style={{ fontWeight: 'bold' }}>Explanation</div>
            <div style={{ margin: '10px 0' }}>
              When you install the app, most scenarios you will use the custom
              Hootsuite template. The template helps creates a carbon copy of a
              Hootsuite board, including the correct monday.com fields and 3
              custom triggers already setup. Note that if you are in the EU
              region, you will not have this automatically setup for you as
              templates are not supported in the EU region.
            </div>
            <Img
              style={{
                marginTop: '10px',
                borderRadius: '5px',
                maxWidth: '900px',
              }}
              src={CustomTriggersImg}
            />
          </Card>
        </CardContent>
      </Card>
    </div>
  )
}

export default HootsuiteKnownLimitations
