import { Card, CardContent, Typography } from '@material-ui/core'
import { ExternalLink } from 'components/ExternalLink'
import styled from 'styled-components'

const Num = styled.span`
  font-weight: bold;
`

// const CustomImg = styled.img`
//   max-height: 300px;
//   margin-right: 20px;
//   border-radius: 5px;
// `

const MondayHootsuiteHowToUse = () => {
  return (
    <div>
      <Card id={'known-limitations'} style={{ marginTop: '80px' }}>
        <CardContent>
          <Typography
            style={{ fontWeight: '700' }}
            color="textSecondary"
            gutterBottom
            variant="h1"
          >
            How to use
          </Typography>
          <Card style={{ margin: '20px 0px', padding: '10px' }}>
            <Typography color="textSecondary" variant="h3">
              Scheduling a Message
            </Typography>
            <div style={{ margin: '10px' }}>
              <div>
                <Num>1.</Num> From your Hootsuite Board in your monday.com
                workspace, click on the “Hootsuite” tab and “create a new draft
                message” from the Social Profiles section.
              </div>
              <div>
                <Num>2.</Num> Click on the “+” sign to draft a new message.
              </div>
              <div>
                <Num>3.</Num> Use the “Schedule Post” wizard to create your
                first post.
              </div>
              <div>
                <Num>Add Media - OPTIONAL</Num> Add either images or video to
                the post. Using the media column in monday.com, upload media,
                the app will then upload the media to Hootsuite. Wait for the
                corresponding media IDs to show in the Media ID column before
                continuing.
              </div>
              <div>
                <Num>4.</Num> Once you hit “Schedule”, navigate to your “Main
                Table” tab and you will see the new scheduled message as an Item
                in the table.
              </div>
              <div>
                <Num>5.</Num> You can validate it was also successfully sent to
                Hootsuite by going to the Planner and seeing your scheduled post
                in the calendar view.
              </div>
              {/* <CustomImg
                  src={MnAppStore}
                  alt={'monday.com app store listing'}
                /> */}
            </div>
          </Card>
          <Card style={{ margin: '20px 0px', padding: '10px' }}>
            <Typography color="textSecondary" variant="h3">
              Send Hootsuite Social Post to monday.com
            </Typography>
            <div style={{ margin: '10px 0' }}>OPTIONAL</div>
            <div style={{ margin: '10px 0' }}>
              If you'd like to send a social post from Hootsuite's dashboard to
              monday.com, check out the{' '}
              <ExternalLink
                href={'https://apps.hootsuite.com/apps/monday'}
                underline={'true'}
              >
                monday.com for Hootsuite app
              </ExternalLink>{' '}
              in Hootsuite's app directory! Please note that this is a separate
              integration from the one you have just installed. The workflows
              are not dependent on each other.
            </div>
          </Card>
        </CardContent>
      </Card>
    </div>
  )
}

export default MondayHootsuiteHowToUse
