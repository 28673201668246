import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import config from '../../../../config'

const Title = styled.h1`
  color: grey;
  margin-bottom: 40px;
`

const Privacy = () => {
  const namespace = `hootsuite`
  const feedbackUrl = config[namespace].feedbackUrl
  return (
    <div>
      <Title>Terms and Conditions of Use</Title>
      <h2>1. Terms</h2>

      <Typography>
        By accessing this web app, accessible from https://monday.com, you are
        agreeing to be bound by these Website Terms and Conditions of Use and
        agree that you are responsible for the agreement with any applicable
        local laws. If you disagree with any of these terms, you are prohibited
        from accessing this site. The materials contained in this web app are
        protected by copyright and trade mark law.
      </Typography>

      <h2>2. Use License</h2>

      <Typography>
        Permission is granted to temporarily download one copy of the materials
        on the web app for personal, non-commercial transitory viewing only.
        This is the grant of a license, not a transfer of title, and under this
        license you may not:
      </Typography>

      <ul>
        <li>modify or copy the materials;</li>
        <li>
          use the materials for any commercial purpose or for any public
          display;
        </li>
        <li>
          attempt to reverse engineer any software contained on J Money Apps
          Inc.'s Website;
        </li>
        <li>
          remove any copyright or other proprietary notations from the
          materials; or
        </li>
        <li>
          transferring the materials to another person or "mirror" the materials
          on any other server.
        </li>
      </ul>

      <Typography>
        This will let J Money Apps Inc. to terminate upon violations of any of
        these restrictions. Upon termination, your viewing right will also be
        terminated and you should destroy any downloaded materials in your
        possession whether it is printed or electronic format.
      </Typography>

      <h2>3. Disclaimer</h2>

      <Typography>
        All the materials on the web app are provided "as is". J Money Apps Inc.
        makes no warranties, may it be expressed or implied, therefore negates
        all other warranties. Furthermore, J Money Apps Inc. does not make any
        representations concerning the accuracy or reliability of the use of the
        materials on its Website or otherwise relating to such materials or any
        sites linked to this Website.
      </Typography>

      <h2>4. Limitations</h2>

      <Typography>
        J Money Apps Inc. or its suppliers will not be hold accountable for any
        damages that will arise with the use or inability to use the materials
        on the web app, even if J Money Apps Inc. or an authorize representative
        of this Website has been notified, orally or written, of the possibility
        of such damage. Some jurisdiction does not allow limitations on implied
        warranties or limitations of liability for incidental damages, these
        limitations may not apply to you.
      </Typography>

      <h2>5. Revisions and Errata</h2>

      <Typography>
        The materials appearing on the web app may include technical,
        typographical, or photographic errors. J Money Apps Inc. will not
        promise that any of the materials in this Website are accurate,
        complete, or current. J Money Apps Inc. may change the materials
        contained on its Website at any time without notice. J Money Apps Inc.
        does not make any commitment to update the materials.
      </Typography>

      <h2>6. Links</h2>

      <Typography>
        J Money Apps Inc. has not reviewed all of the sites linked to its
        Website and is not responsible for the contents of any such linked site.
        The presence of any link does not imply endorsement by J Money Apps Inc.
        of the site. The use of any linked website is at the user’s own risk.
      </Typography>

      <h2>7. Site Terms of Use Modifications</h2>

      <Typography>
        J Money Apps Inc. may revise these Terms of Use for its Website at any
        time without prior notice. By using this Website, you are agreeing to be
        bound by the current version of these Terms and Conditions of Use.
      </Typography>

      <h2>8. Your Privacy</h2>

      <Typography>See Privacy Policy for more details.</Typography>

      <h2>9. Governing Law</h2>

      <Typography>
        Any claim related to the web app shall be governed by the laws of ca
        without regards to its conflict of law provisions.
      </Typography>

      <div style={{ marginTop: '20px' }}>
        <a href={feedbackUrl} target={'_blank'} rel={'noopener noreferrer'}>
          Feedback
        </a>
      </div>
    </div>
  )
}

export default Privacy
