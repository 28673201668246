import styled from 'styled-components'

const HsDashContainer = styled.div`
  overflow: hidden;
`
const Topbar = styled.img`
  display: block;
  max-width: 100%;
`
const LowerDashboard = styled.div`
  display: flex;
`

const Sidebar = styled.img`
  width: 7.7%;
  height: 100%;
`

const AppStreams = styled.img`
  width: 100%;
  height: 100%;
`

const Streams = ({ app }) => {
  const namespace = 'hootsuite'
  return (
    <HsDashContainer>
      {app.components[0] === 'App Stream' && (
        <AppStreams
          alt={`${app.name.lowerCase} Screenshot 1`}
          src={`/images/${namespace}/apps/${app.name.lowerCase}/hs-${app.name.lowerCase}-screenshot-1.jpg`}
        />
      )}
    </HsDashContainer>
  )
}

export { Streams }
