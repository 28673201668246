import Installation from './Installation'
import Overview from './Overview'
import Requirements from './Requirements'
import Usage from './Usage'
import Faq from './Faq'
import Privacy from './Privacy'
import TermsOfService from './TermsOfService'

const GeneralBody = ({ app }) => {
  const namespace = `hootsuite`
  return (
    <div>
      <Overview app={app} />
      <Requirements app={app} />
      <Installation app={app} />
      <Usage app={app} />
      <Faq app={app} />
      <Privacy app={app} namespace={namespace} />
      <TermsOfService app={app} namespace={namespace} />
    </div>
  )
}

export default GeneralBody
