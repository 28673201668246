import styled from 'styled-components'
import config from '../../../../../../../config'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const Link = styled.a`
  font-family: Roboto, sans-serif;
  color: grey;
  text-align: center;
`

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    margin: theme.spacing(2, 0),
  },
}))

const OtherNav = () => {
  const parts = window.location.pathname.split('/')
  const n = parts[1]
  const namespace = n === 'user-guide' || n === 'updates' ? 'hootsuite' : n
  const classes = useStyles()
  const feedbackUrl = config[namespace].feedbackUrl

  return (
    <div>
      <Divider className={classes.divider} />
      <Link href={`/updates`}>Updates</Link>
      <Divider className={classes.divider} />
      <Link href={feedbackUrl} target={'_blank'} rel={'noopener nofollow'}>
        Feedback
      </Link>
      <Divider className={classes.divider} />
      {/* <Link href={`/${namespace}/privacy`}>Privacy</Link> */}
    </div>
  )
}

export default OtherNav
