import { ExternalLink } from 'components/ExternalLink'
import GenericSection from 'views/UserGuide/components/GenericSection'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    margin: '0 0 80px 0',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  media: {
    paddingTop: theme.spacing(2),
    height: 400,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto',
    },
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
}))

const BrightcoveGuide = ({ className, app, ...rest }) => {
  const classes = useStyles()
  const text = () => {
    return (
      <div>
        The Brightcove account must first be connected to the app. For detailed
        instructions on how to do this, see the{' '}
        <ExternalLink
          href={
            'https://studio.support.brightcove.com/admin/managing-api-authentication-credentials.html'
          }
        >
          User Guide for Managing API Credentials.
        </ExternalLink>
      </div>
    )
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="h1"
            >
              Connect Brightcove Account
            </Typography>
            <GenericSection
              sectionText={text()}
              sectionImageUrl={`/images/hootsuite/apps/brightcove/brightcove-connect-account.png`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default BrightcoveGuide
