import GeneralTermsOfService from './GeneralTermsOfService'

const TermsOfService = ({ namespace, app }) => {
  return (
    <div id={'terms'}>
      <GeneralTermsOfService app={app} namespace={namespace} />
    </div>
  )
}

export default TermsOfService
