import styled from 'styled-components'
import config from 'config'
import { Typography } from '@material-ui/core'

const ListingContainer = styled.div`
  margin: 20px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  display: flex;
  padding: 20px;
  max-width: 850px;
  max-height: 270px;
`

const AppLogo = styled.img`
  margin-right: 20px;
  width: 75px;
  height: 75px;
`
const AppName = styled.div`
  margin-bottom: 10px;
`

const Description = styled.div`
  margin-bottom: 10px;
`

const ExternalLink = styled.a`
  text-decoration: none;
  color: royalblue;
`

const StarRating = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const InstallBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const InstallButton = styled(Typography)`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: bold;
  color: black;
`

const Stars = () => (
  <StarRating>
    <span role="img" aria-label={'star rating'}>
      ⭐️
    </span>
    <span role="img" aria-label={'star rating'}>
      ⭐️
    </span>
    <span role="img" aria-label={'star rating'}>
      ⭐️
    </span>
    <span role="img" aria-label={'star rating'}>
      ⭐️
    </span>
    <span role="img" aria-label={'star rating'}>
      ⭐️
    </span>
  </StarRating>
)

const Listing = ({ app }) => {
  const namespace = 'hootsuite'
  const hsAppUrl =
    app.name.lowerCase === 'wordpress'
      ? `https://apps.hootsuite.com/apps/wordpress-app`
      : `https://apps.hootsuite.com/apps/${app.name.lowerCase}`
  return (
    <ListingContainer>
      <AppLogo
        src={`/images/${namespace}/apps/${app.name.lowerCase}/${app.name.lowerCase}-256.png`}
        alt={'App Logo'}
      />
      <div>
        <AppName>
          <Typography variant={'h3'}>{app.name.titleCase}</Typography>
        </AppName>
        <Description>
          <Typography>{app.description}</Typography>
        </Description>
        <Typography>
          By:{' '}
          <ExternalLink
            href={hsAppUrl}
            target={'_blank'}
            rel="noopener nofollow"
          >
            {config.provider.name}
          </ExternalLink>
        </Typography>
        <Stars />
        <InstallBar>
          <div>
            <Typography>We'd love your feedback!</Typography>
            <Typography>
              <ExternalLink
                href={hsAppUrl}
                target={'_blank'}
                rel="noopener nofollow"
              >
                Rate this app
              </ExternalLink>
            </Typography>
          </div>

          <ExternalLink
            href={hsAppUrl}
            target={'_blank'}
            rel="noopener nofollow"
          >
            <InstallButton>Install</InstallButton>
          </ExternalLink>
        </InstallBar>
      </div>
    </ListingContainer>
  )
}

export default Listing
