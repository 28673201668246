import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Body } from './components'
import config from '../../config'
import Updates from './components/Updates'
import Privacy from './components/Privacy'
import TermsOfService from './components/TermsOfService'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))

const UserGuide = () => {
  const location = useLocation()
  const parts = location.pathname.split('/')
  const n = parts[1]
  const namespace = n === 'user-guide' ? 'hootsuite' : n
  const lastSegment = parts.pop()
  const vendorName = lastSegment
  const app =
    vendorName !== 'updates' || vendorName === 'privacy'
      ? config[namespace].apps.find((a) => a.name.lowerCase === vendorName)
      : undefined
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          {vendorName !== 'updates' &&
            vendorName !== 'privacy' &&
            vendorName !== 'terms' && <Body app={app} namespace={namespace} />}
          {vendorName === 'updates' && <Updates />}
          {vendorName === 'privacy' && <Privacy />}
          {vendorName === 'terms' && <TermsOfService />}
        </Grid>
      </Grid>
    </div>
  )
}

export default UserGuide
