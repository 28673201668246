import styled from 'styled-components'
import LaunchIcon from '@material-ui/icons/Launch'
import ReplayIcon from '@material-ui/icons/Replay'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import InstagramIcon from '@material-ui/icons/Instagram'
import HsPost from './HsPost'

const Container = styled.div`
  max-width: 500px;
  min-width: 300px;
  border: 1px solid grey;
  margin: 20px;
  opacity: 50%;
`

const Header = styled.div`
  border-bottom: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
`

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  color: grey;
`

const StreamTitle = styled.h3`
  margin: 10px;
`

const StreamSubTitle = styled.p`
  color: grey;
  font-weight: bold;
`

const Body = styled.div`
  height: 800px;
  overflow: scroll;
`

const HsStream = ({ app }) => {
  return (
    <Container>
      <Header>
        <HeaderLeft>
          <InstagramIcon
            style={{ background: '#E1306C', color: 'white', padding: '3px' }}
          />
          <StreamTitle>My Posts</StreamTitle>
          <StreamSubTitle>appdemojody</StreamSubTitle>
        </HeaderLeft>
        <HeaderRight>
          <LaunchIcon
            style={{
              padding: '3px',
              borderRadius: '50%',
              backgroundColor: 'gold',
            }}
          />
          <ReplayIcon style={{ margin: '0 10px' }} />
          <MoreHorizIcon />
        </HeaderRight>
      </Header>
      <Body>
        <HsPost app={app} />
        <HsPost app={app} />
        <HsPost app={app} />
      </Body>
    </Container>
  )
}

export default HsStream
