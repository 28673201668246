import HootsuiteInstallation from './HootsuiteInstallation'

const CustomMondayInstallation = ({ app }) => {
  return (
    <div>
      {app?.name?.lowerCase === 'hootsuite' && <HootsuiteInstallation />}
    </div>
  )
}

export default CustomMondayInstallation
