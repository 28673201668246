const getParameterByName = (name) => {
  const params = new URLSearchParams(window.location.search)
  return params.get(name)
}

const getVendorNameFromLocation = ({ location }) => {
  if (location) {
    const parts = location.pathname.split('/')
    const lastSegment = parts.pop() || parts.pop()
    return lastSegment
  } else {
    return ''
  }
}

const getNamespaceFromLocation = () => {
  const parts = window.location.pathname.split('/')
  const n = parts[1]
  const namespace = n === 'user-guide' ? 'hootsuite' : n
  return namespace
}

const getVendorConfig = ({ config, location }) => {
  const vendorName = getVendorNameFromLocation({ location })
  return config.hootsuite.apps.find((app) => app.name.lowerCase === vendorName)
}

const isActiveApp = ({ appName, location }) => {
  const vendorName = getVendorNameFromLocation({ location })
  return vendorName === appName
}

export {
  getParameterByName,
  getVendorNameFromLocation,
  getNamespaceFromLocation,
  getVendorConfig,
  isActiveApp,
}
