import GenericSection from '../../../../../GenericSection'
import HsLogin from './HsLogin'

const HsAuthFlow = ({ app }) => (
  <div>
    {app.hasHsAuth && (
      <div>
        <GenericSection
          sectionText={`You will be taken to a screen with a link to login to Hootsuite. Follow the authorization process. Hootsuite Login is required for uploading videos and files to the Hootsuite Composer.`}
        />
        <HsLogin app={app} />
      </div>
    )}
  </div>
)

export default HsAuthFlow
