// import Installation from './Installation'
import Overview from './Overview'
import Requirements from './Requirements'
import Usage from './Usage'
import Faq from './Faq'
import Privacy from './Privacy'
import TermsOfService from './TermsOfService'
import Demo from './Demo'
import CustomMondayHowToUse from './Usage/CustomMondayHowToUse'
import CustomMondayKnownLimitations from './Usage/CustomMondayKnownLimitations'
import CustomMondayInstallation from './Usage/CustomMondayInstallation'

const GeneralBody = ({ app }) => {
  return (
    <div>
      <Overview app={app} />
      <Requirements app={app} />
      {/* <Installation app={app} /> */}
      <Usage app={app} />
      <CustomMondayInstallation app={app} />
      <CustomMondayHowToUse app={app} />
      <CustomMondayKnownLimitations app={app} />
      <Faq app={app} />
      <Demo app={app} />
      <Privacy app={app} />
      <TermsOfService app={app} />
      {/* <Pricing app={app} /> */}
    </div>
  )
}

export default GeneralBody
