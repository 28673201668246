import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const HsLoginViewContainer = styled.div`
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 600px;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 5px;
`

const HsLoginContainer = styled.div`
  padding: 10px;
  text-align: center;
`
const RegisterContainer = styled.div`
  margin-top: 10px;
`

const RegisterLink = styled.a`
  text-decoration: none;
  color: ${(p) => p.primaryColor};
`

const HsLogin = ({ app }) => {
  const useStyles = makeStyles(() => ({
    primary: {
      color: 'white',
      backgroundColor: 'black',
      '&:hover, &:focus': {
        color: 'black',
      },
    },
  }))
  const classes = useStyles()
  return (
    <HsLoginViewContainer>
      <HsLoginContainer>
        <Button className={classes.primary} color={'secondary'} size={'large'}>
          Login to Hootsuite
        </Button>

        <RegisterContainer>
          <p>Don't have an account?</p>
          <p>
            <RegisterLink
              primaryColor={app.primaryColor}
              target={'_blank'}
              href={'#'}
              rel={'noreferrer noopener'}
            >
              Create an account
            </RegisterLink>
          </p>
        </RegisterContainer>
      </HsLoginContainer>
    </HsLoginViewContainer>
  )
}

export default HsLogin
