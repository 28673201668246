import monday from './monday'
import hootsuite from './hootsuite'

const config = {
  siteTitle: 'J Money Apps',
  provider: {
    name: 'J Money Apps',
  },
  settingsLinks: [
    { text: 'Help', href: 'https://hootsuite.com/help' },
    { text: 'Developer', href: 'https://jmoneyapps.com' },
  ],
  hootsuite,
  monday,
}

export default config
